/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import reportDropdown from '@/components/reportDropdown/index.vue';
export const reportColumns = ((list: any[], dropdownChangeCallBack: FunctionStringCallback): any => {
  const columns = [
    {
      title: '编号',
      key: 'rank',
    },
    {
      title: '报告名称',
      key: 'name',
      ellipsis: true,
    },
    {
      key: 'type',
      renderHeader: (h: any) => h(reportDropdown, {
        props: {
          list,
        },
        on: {
          dropdownChange: (params: any) => {
            dropdownChangeCallBack(params);
          },
        },
      }),
    },
    {
      title: '生成时间',
      key: 'date',
    },
    {
      title: '操作',
      key: 'operate',
      slot: 'operate',
    },
  ];
  return columns;
});
