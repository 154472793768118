



















































// eslint-disable no-param-reassign
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import { prefix, mallRankListInfo, reportObj, portraitCoding } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getMallReportsSvc } from '@/services/api';
import ExportJsonExcel from 'js-export-excel';
import * as Services from '@/services/api';
import { getContainer, getDateAryByStartEnd, getReportType, groupAry, processBeforeDateByDuration } from '@/components';
import checkDetail from './checkDetail/index.vue';
import { reportColumns } from '@/utils/tableColumns/columns';
interface FlowNumS {
  flow: number;
  mall_id: number;
  new_num: string;
  new_percent: string;
  num: number;
  old_num: string;
  percent: string;
  time: string;
  date: string;
}
@Component({
  components: {
    checkDetail,
  },
})
export default class ReportPage extends Vue {
  $dayjs: any;
  $stores: any;
  isCheck = this.$store.state.global.isChecked;
  isDownloadPPT = this.$store.state.global.isDownloadPPT;
  isDownloadExcel = false;
  currentValue = 1;
  loading = true;
  date = this.$store.state.mall.date;
  prefix: string = prefix;
  total = 0;
  tableHeight = 0;
  excelArr: any = [];
  dropType = 'w';
  pageSize = 15;
  arrowDown: any = '';
  list = Object.values(reportObj);
  columns1: any = reportColumns(this.list, (params: any) => {
    this.dropTypeChangeHandler(params);
  });
  data1: any = [];
  $beaconAction: any;
  propertyObj: any = portraitCoding;
  mallIdField = 'mall_id';
  created(): void{
    this.dropType = getReportType();
  }

  get currentReportNameItem(): any {
    // console.log(this.)
    return reportObj[this.dropType];
  }

  get reportName(): string {
    return this.currentReportNameItem.name;
  }

  get reportTitle(): string {
    return this.currentReportNameItem.title;
  }

  get excelTitle(): string {
    return this.currentReportNameItem.excelTitle;
  }

  get tableDropType(): string {
    return this.currentReportNameItem.type;
  }

  get reportNames(): string {
    return this.currentReportNameItem.reportName;
  }

  get mallStore(): any {
    return this.$store.state.mall;
  }

  get mallName(): string {
    return this.mallStore.mallName;
  }

  get mallId(): number {
    return this.mallStore.mallId;
  }

  get dropTypes(): string {
    return this.currentReportNameItem.dataType;
  }

  @Watch('$store.state.mall.mallId')
  mallIdChange(): void {
    this.currentValue = 1;
    this.initData(this.currentValue);
  }

  @Watch('$store.state.global.isChecked', { immediate: false, deep: true })
  checkStatus(): void {
    this.isCheck = this.$store.state.global.isChecked;
  }

  @Watch('$store.state.global.isDownloadPPT', { immediate: false, deep: true })
  checkDownloadPPT(): void {
    this.isDownloadPPT = this.$store.state.global.isDownloadPPT;
  }

  async mounted(): Promise<void> {
    this.tableHeight = getContainer(260);
    // this.initData(this.currentValue);
  }

  currentPageChange(val: number): void {
    this.currentValue = val;
    this.initData(val);
  }

  dropTypeChangeHandler(params: any): void{
    const { value } = params;
    this.dropType = value;
    localStorage.setItem('reportDropType', this.dropType);
    this.currentValue = 1;
    this.initData(this.currentValue);
    // 埋点
    this.buriedPointHandler('report_type_click', {
      type: this.reportTitle,
    });
  }

  async initData(page: number): Promise<void> {
    this.data1 = [];
    this.total = 0;
    this.loading = true;
    const res = await getMallReportsSvc({
      ['mall_id']: this.mallId,
      duration: this.dropType,
      page,
      rows: 15,
      user_key: this.$store.state.user.userKey,
    });

    if (res.status === 0) {
      const { total, data } = res;
      this.total = total;
      this.loading = false;
      data.forEach((item: any) => {
        const { rank, date, report_status: reportStatus, first_date: firstDate, last_date: lastDate,
          text, data_date: dataDate } = item;
        const names = `${firstDate}至${lastDate} ${this.mallName}`;
        this.data1.push({
          rank,
          type: text,
          name: `${names}客流报告`,
          date,
          status: Number(reportStatus),
          lastDate,
          firstDate,
          dataDate,
          duration: this.dropType,
          reportPage: this.currentReportNameItem,
          reportName: `${names}${this.reportNames}`,
        });
      });
    }
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  processHeader(data: any, field: string[]): any {
    const result: any = {};
    data.forEach((item: any, index: number) => {
      const fields = field[index];
      result[fields] = item;
    });
    return result;
  }

  processData(data: any, field: string[], field2: string[]): any[] {
    const result: any[] = [];
    data.forEach((item: any) => {
      const obj: any = {};
      field2.forEach((key: string, index: number) => {
        if (item[key]) {
          const value = item[key];
          const fields = field[index];
          obj[fields] = value;
        }
      });
      result.push(obj);
    });
    return result;
  }

  setCacheExportData(exportKey: string, option: any): void{
    // 配置二次导出缓存
    localStorage.setItem(exportKey, JSON.stringify(option));
  }

  getCacheExportData(exportKey: string): any {
    // 获取二次导出缓存
    const eData = localStorage.getItem(exportKey);
    const exportYData = eData ? eData : false;
    let option = '';
    if (exportYData) {
      option = JSON.parse(exportYData);
    }
    return option;
  }

  async downloadExcel(params: any): Promise<void> {
    // 周 每周最后一天
    // 月，每月第一天
    const { firstDate, lastDate, dataDate, reportName } = params;
    this.isDownloadExcel = true;
    const mallId = 'mall_id';
    const startTime = dataDate;

    // 导出key mall_id&duration&date
    const exportKey = `report&${this.mallId}&${this.dropType}&${dataDate}`;
    const exportData = this.getCacheExportData(exportKey);
    if (exportData) {
      this.isDownloadExcel = false;
      const toExcel = new ExportJsonExcel(exportData); // new
      toExcel.saveExcel();
      return;
    }

    const beforeDateObj = processBeforeDateByDuration(dataDate, this.dropType);
    // before
    // dataDate: '', // 数据取数日期
    // firstDate: '', // 开始日期
    // lastDate: '', // 结束日期
    // date: '', // 数据生成日期
    const { dataDate: beforeDataDate, firstDate: beforeFristDate,
      lastDate: beforeLastDate } = beforeDateObj;
    const dayList = getDateAryByStartEnd(firstDate, lastDate);
    const lastDayList = getDateAryByStartEnd(beforeFristDate, beforeLastDate);
    const beforeTime = beforeDataDate;

    const res = await Services.getMallRankDataBasesMallRankingSvc({
      [mallId]: this.mallId,
      duration: this.dropTypes,
      type: '301',
      property: '1',
      subtype: '1',
      date: startTime,
    });
    const res1 = await Services.getMallRankDataBasesMallRankingSvc({
      [mallId]: this.mallId,
      duration: this.dropTypes,
      type: '301',
      property: '1',
      subtype: '1',
      date: beforeTime,
    }, false);
    // const res3 = await Services.getMallRankDataBasesMallRankingSvc({
    //   [mallId]: this.mallId,
    //   duration: this.dropType,
    //   type: '301',
    //   property: '2',
    //   subtype: '1',
    //   date: startTime,
    // });
    // const res4 = await Services.getMallRankDataBasesMallRankingSvc({
    //   [mallId]: this.mallId,
    //   duration: this.dropType,
    //   type: '301',
    //   property: '2',
    //   subtype: '1',
    //   date: beforeTime,
    // });
    const dateArray = 'date_ary';
    const res5 = await Services.getMallPortaitsByMallIdSSvc({
      [mallId]: this.mallId,
      duration: 'd',
      type: '1',
      property: '50',
      [dateArray]: dayList,
    });
    const res6 = await Services.getMallPortaitsByMallIdSSvc({
      [mallId]: this.mallId,
      duration: 'd',
      type: '1',
      property: '50',
      [dateArray]: lastDayList,
    }, false);

    const res7 = await Services.getLocaltiyAndOutsidePassengerFlowSSvc({
      [mallId]: this.mallId,
      [dateArray]: [startTime],
      duration: this.dropTypes,
    });
    const res8 = await Services.passengerFlowTops({
      [mallId]: this.mallId,
      date: startTime,
      duration: this.dropTypes,
    });

    // 年度数据太大，分批执行
    const dayLists = groupAry(dayList, 60);
    let res9Data: FlowNumS[] = [];
    let sumNum = 0;
    let sumFlow = 0;
    Promise.all(dayLists.map(async (item: string[]) => {
      const params = {
        [mallId]: this.$store.state.mall.mallId,
        [dateArray]: item,
        duration: 'd',
        date_start: item[0],
        date_end: item[item.length - 1],
      };
      const flowData = await Services.passengerFlowData(params);
      const {
        datas: data,
      } = flowData;
      return data;
    })).then((results: FlowNumS[]) => {
      const res9: any = {};
      results.forEach((item: any) => {
        item.forEach((item1: FlowNumS) => {
          const { time, num, flow } = item1;
          res9[time] = item1;
          sumNum += num;
          sumFlow += flow;
        });
      });
      res9Data = Object.values(res9);
    });

    // 获取上一个月的数据，较上月
    const lastDayLists = groupAry(lastDayList, 60);
    const results1 = await Promise.all(lastDayLists.map(async (item: string[]) => {
      const params = {
        [mallId]: this.$store.state.mall.mallId,
        [dateArray]: item,
        duration: 'd',
        date_start: item[0],
        date_end: item[item.length - 1],
      };
      const result = await Services.passengerFlowData(params, false);
      return result;
    }));
    let sumNum1 = 0;
    let sumFlow1 = 0;
    results1.forEach((res: any) => {
      const { num, flow } = res.sumObj;
      sumNum1 += num;
      sumFlow1 += flow;
    });
    const numCompared = isNaN(sumNum - sumNum1) ? '无法比较' : (((sumNum - sumNum1) / sumNum1) * 100).toFixed(2);
    const visitCompared = isNaN(sumFlow - sumFlow1) ? '无法比较' : (((sumFlow - sumFlow1) / sumFlow1) * 100).toFixed(2);

    const startPercent = 'start_percent';
    const endPercent = 'end_percent';
    const res10 = await Services.getGridBussinessCircleWeekMonthSvc({
      [mallId]: this.mallId,
      date: startTime,
      [startPercent]: 0,
      [endPercent]: 100,
      property: '2',
      type: '1',
      duration: this.dropTypes,
    });
    const res11 = await Services.getGridBussinessCircleWeekMonthSvc({
      [mallId]: this.mallId,
      date: beforeTime,
      [startPercent]: 0,
      [endPercent]: 100,
      property: '2',
      type: '1',
      duration: this.dropTypes,
    }, false);
    const res12 = await Services.getMallRankingListWeekMonthSvc({
      date: startTime,
      [mallId]: this.mallId,
      duration: this.dropTypes,
    });
    const res13 = await Services.getMallRankingListWeekMonthSvc({
      date: beforeTime,
      [mallId]: this.mallId,
      duration: this.dropTypes,
    }, false);
    const portraitNaturalList = [101, 102, 103, 105, 106];
    const economyList = [107, 108, 109, 110, 111, 112, 116, 115];
    const arrivalList = [501, 502, 503, 504, 505];

    const jType = 'j_type';
    const sumType = 'sum_type';
    const portraitAnalysisList: any = [];
    const competitionRes = await Services.getMallRankDataByMallIdSvc({
      [mallId]: this.mallId,
      start: 0,
      end: 100,
      duration: this.dropTypes,
      date: startTime,
    });
    const competitionResLast = await Services.getMallRankDataByMallIdSvc({
      date: beforeTime,
      [mallId]: this.mallId,
      start: 0,
      end: 100,
      duration: this.dropTypes,
    }, false);
    if (competitionRes?.data?.[0]?.obj) {
      // eslint-disable-next-line
      for (const i in competitionRes.data[0].obj) {
        if (Number(i) <= 33) {
          competitionRes.data[0].obj[i].title = mallRankListInfo.slice(0, 33)[Number(i) - 1].name
            ? mallRankListInfo.slice(0, 33)[Number(i) - 1].name : '';
          competitionRes.data[0].obj[i].rankList = competitionResLast?.data?.[0]
            ?.obj?.[i]?.rank
            ? competitionResLast.data[0].obj[i].rank
            - competitionRes.data[0].obj[i].rank
            : '无法比较';
        }
      }
    }
    await Promise.all(portraitNaturalList.map(async (i: number) => {
      const jTypeValue = i === 102 ? 1 : 0;
      const data = await Services.portraitByMallId({
        [mallId]: this.mallId,
        date: startTime,
        type: i,
        duration: this.dropTypes,
        [jType]: jTypeValue,
        [sumType]: 0,
      }, false);
      return {
        data,
        result: data.datas,
        type: i,
      };
    })).then((results: any) => {
      results.forEach((item: any) => {
        const { type, result } = item;
        result.forEach((k: any) => {
          k.name = '自然属性';
          k.percent = k.percent !== -1 ? `${k.percent}%` : '暂无数据';
          k.dimension = this.propertyObj[type];
        });
        portraitAnalysisList.push(...result);
      });
    });
    await Promise.all(economyList.map(async (i: number) => {
      const jTypeValue = i === 116 ? 2 : 0;
      const data = await Services.portraitByMallId({
        [mallId]: this.mallId,
        date: startTime,
        type: i,
        duration: this.dropTypes,
        [jType]: jTypeValue,
        [sumType]: 0,
      }, false);
      return {
        data,
        result: data.datas,
        type: i,
      };
    })).then((results: any) => {
      results.forEach((item: any) => {
        const { type, result } = item;
        result.forEach((k: any) => {
          k.name = '财富属性';
          k.percent = k.percent !== -1 ? `${k.percent}%` : '暂无数据';
          k.dimension = this.propertyObj[type];
        });
        portraitAnalysisList.push(...result);
      });
    });
    Promise.all(arrivalList.map(async (i: number) => {
      const data = await Services.portraitByMallId({
        [mallId]: this.mallId,
        date: startTime,
        type: i,
        duration: this.dropTypes,
        [jType]: 0,
        [sumType]: 1,
      }, false);
      return {
        data,
        result: data.datas,
        type: i,
      };
    })).then((results: any) => {
      results.forEach((item: any) => {
        const { type, result } = item;
        result.forEach((k: any) => {
          k.name = '到访偏好';
          k.percent = k.percent !== -1 ? `${k.percent}%` : '暂无数据';
          k.dimension = this.propertyObj[type];
        });
        portraitAnalysisList.push(...result);
      });
      const option: any = {};
      option.fileName = `统计报告-${reportName}数据导出`;
      this.buriedPointHandler('report_download_click', { name: option.fileName });
      option.datas = [];
      // start
      const field = ['one', 'tow', 'three', 'four', 'five'];

      const sumHeader = ['到访客流总人数', `较上${this.excelTitle}`];
      // const sumHeaders = this.processHeader(sumHeader, field);
      const sumData = [
        {
          total: sumNum,
          lastWeek: numCompared,
        },
      ];
      const sumDatas = this.processData(sumData, field, ['total', 'lastWeek']); // 到访客流总人数
      const names: any = {
        xw: '周去重',
        xm: '月去重',
        xh: '节假日去重',
        s: '月度去重累加',
        y: '月度去重累加',
      };
      // 人数;
      const xSumData = [
        {
          total: res.status === 0 && res.data !== -1 ? res.data : '暂无数据',
          lastWeek:
              res.status === 0 && res1.status === 0 && res1.data !== -1
                ? `${Number(((res.data - res1.data) / res1.data) * 100).toFixed(2)}%`
                : '无法比较',
        },
      ];
      const xSumHeader = [`${names[this.dropTypes]}客流总人数`, `较上${this.excelTitle}`];
      const xSumHeaders = this.processHeader(xSumHeader, field);
      const xSumDatas = this.processData(xSumData, field, ['total', 'lastWeek']); // 去重到访客流总人数
      xSumDatas.unshift(xSumHeaders);
      // 人次
      const flowData = [{
        total: sumFlow,
        lastWeek: visitCompared,
      }];
      const flowHeader = ['到访客流总人次', `较上${this.excelTitle}`];
      const flowHeaders = this.processHeader(flowHeader, field);
      const flowDatas = this.processData(flowData, field, ['total', 'lastWeek']); // 到访客流总人次
      flowDatas.unshift(flowHeaders);

      // 新客流总数及占比
      const newNumData = [
        {
          total:
              res5.status === 0 && res5.s_new_num !== -1
                ? res5.s_new_num
                : '暂无数据',
          lastWeek:
              res5.status === 0
              && res5.s_new_num !== -1
              && res6.status === 0
              && res6.s_new_num !== -1
                ? `${Number(((res5.s_new_num - res6.s_new_num) / res6.s_new_num) * 100).toFixed(2)}%`
                : '无法比较',
          percent:
              res5.status === 0 && res5.percent ? `${res5.percent.toFixed(2)}%` : '暂无数据',
          lastPercent:
              res5.status === 0
              && res5.percent
              && res6.status === 0
              && res6.percent
              && res5.percent !== -1
              && res6.percent !== -1
                ? `${Number(res5.percent - res6.percent).toFixed(2)}%`
                : '无法比较',
        },
      ];
      const newNumHeader = [
        '新客流总数',
        `较上${this.excelTitle}`,
        '新客流占比',
        `较上${this.excelTitle}`,
      ];
      const newNumHeaders = this.processHeader(newNumHeader, field);
      const newNumDatas = this.processData(
        newNumData, field,
        ['total', 'lastWeek', 'percent', 'lastPercent'],
      ); // 到访客流总人数
      newNumDatas.unshift(newNumHeaders);

      // 客流人数占比
      const sourceNumData = [
        {
          local:
              res7.status === 0
              && Object.values(res7.data)[0]
              && (Object.values(res7.data)[0] as any).localtiy_ratio
              && (Object.values(res7.data)[0] as any).localtiy_ratio !== -1
                ? `${(Object.values(res7.data)[0] as any).localtiy_ratio}%`
                : '暂无数据',
          nonlocal:
              res7.status === 0
              && Object.values(res7.data)[0]
              && (Object.values(res7.data)[0] as any).outside_ratio
              && (Object.values(res7.data)[0] as any).outside_ratio !== -1
                ? `${(Object.values(res7.data)[0] as any).outside_ratio}%`
                : '暂无数据',
        },
      ];
      const sourceNumHeader = ['本地客流占比', '外地客流占比'];
      const sourceNumHeaders = this.processHeader(sourceNumHeader, field);
      const sourceNumDatas = this.processData(sourceNumData, field, ['local', 'nonlocal']); // 到访客流总人数
      sourceNumDatas.unshift(sourceNumHeaders);

      // 外地客群城市分布
      res8.datas.forEach((i: string) => {
        (i as any).percent = (i as any).percent !== -1 ? `${(i as any).percent}%` : '暂无数据';
      });
      const outsideData = res8.datas;
      const outsideHeader = ['外地客群城市分布', '占比'];
      const outsideHeaders = this.processHeader(outsideHeader, field);
      const outsideDatas = this.processData(outsideData, field, ['city', 'percent']); // 到访客流总人数
      outsideDatas.unshift(outsideHeaders);

      // 详细客流信息
      res9Data.forEach((item: FlowNumS) => {
        const { new_percent, time } = item;
        item.date = this.$dayjs(time).format('YYYY-MM-DD');
        item.new_percent = new_percent !== '-1' && new_percent !== '暂无数据'
          ? `${new_percent}%`
          : '暂无数据';
      });
      const numInfoData = res9Data;
      const numInfoHeader = [
        '日期',
        '客流人数',
        '客流人次',
        '新客流人数',
        '新客流占比',
      ];
      const numInfoHeaders = this.processHeader(numInfoHeader, field);
      const numInfoDatas = this.processData(
        numInfoData,
        field, ['date', 'num', 'flow', 'new_num', 'new_percent'],
      ); // 到访客流总人数
      numInfoDatas.unshift(numInfoHeaders);
      numInfoDatas.unshift({ one: '详细数据' });
      const sheetData1 = [...sumDatas, ...xSumDatas, ...flowDatas, ...newNumDatas,
        ...sourceNumDatas, ...outsideDatas, ...numInfoDatas];
      option.datas.push({
        sheetData: sheetData1,
        sheetName: '客流统计',
        sheetFilter: field,
        sheetHeader: sumHeader,
        columnWidths: [10, 10, 10, 10, 10],
      });
      // end
      const bussinessList: any = [];
      const valueList = [
        `${
          res12.data.percent_25 !== -1
            ? `${res12.data.percent_25}%`
            : '暂无数据'
        }`,
        `${
          res12.data.percent_9 !== -1 ? `${res12.data.percent_9}%` : '暂无数据'
        }`,
        res12.data.pop_base_25 !== -1 ? res12.data.pop_base_25 : '暂无数据',
        res12.data.pop_base_9 !== -1 ? res12.data.pop_base_9 : '暂无数据',
        `${
          res12.data.pop_ratio_25 !== -1
            ? `${res12.data.pop_ratio_25}%`
            : '暂无数据'
        }`,
        `${
          res12.data.pop_ratio_9 !== -1
            ? `${res12.data.pop_ratio_9}%`
            : '暂无数据'
        }`,
        res12.data.pop_num_25 ? res12.data.pop_num_25 : '暂无数据',
        res12.data.pop_num_9 ? res12.data.pop_num_9 : '暂无数据',
        res10.source_sum ? res10.source_sum : '暂无数据',
        `${res10.percent !== -1 ? `${res10.percent}%` : '暂无数据'}`,
        res10.pop_sum !== -1 ? res10.pop_sum : '暂无数据',
        res10.area !== -1 ? res10.area : '暂无数据',
      ];
      const nameList = [
        '25平方公里客流渗透率',
        '9平方公里客流渗透率',
        '25平方公里居住人口数',
        '9平方公里居住人口数',
        '25平方公里客流总数占比',
        '9平方公里客流总数占比',
        '25平方公里客流总数',
        '9平方公里客流总数',
        '商圈客流人数',
        '商圈渗透率',
        '商圈总人口',
        '商圈总面积',
      ];
      const compareList = [
        `${
          res12.data.percent_25
          && res13.data.percent_25
          && res12.data.percent_25 !== -1
          && res13.data.percent_25 !== -1
            ? `${(
              Number((res12.data.percent_25 - res13.data.percent_25)
                    / res13.data.percent_25) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.percent_9
          && res13.data.percent_9
          && res12.data.percent_9 !== -1
          && res13.data.percent_9 !== -1
            ? `${(
              Number((res12.data.percent_9 - res13.data.percent_9)
                    / res13.data.percent_9) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.pop_base_25
          && res13.data.pop_base_25
          && res12.data.pop_base_25 !== -1
          && res13.data.pop_base_25 !== -1
            ? `${(
              Number((res12.data.pop_base_25 - res13.data.pop_base_25)
                    / res13.data.pop_base_25) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.pop_base_9
          && res13.data.pop_base_9
          && res12.data.pop_base_9 !== -1
          && res13.data.pop_base_9 !== -1
            ? `${(
              Number((res12.data.pop_base_9 - res13.data.pop_base_9)
                    / res13.data.pop_base_9) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.pop_ratio_25
          && res13.data.pop_ratio_25
          && res12.data.pop_ratio_25 !== -1
          && res13.data.pop_ratio_25 !== -1
            ? `${(
              Number((res12.data.pop_ratio_25 - res13.data.pop_ratio_25)
                    / res13.data.pop_ratio_25) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.pop_ratio_9
          && res13.data.pop_ratio_9
          && res12.data.pop_ratio_9 !== -1
          && res13.data.pop_ratio_9 !== -1
            ? `${(
              Number((res12.data.pop_ratio_9 - res13.data.pop_ratio_9)
                    / res13.data.pop_ratio_9) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.pop_num_25
          && res13.data.pop_num_25
          && res12.data.pop_num_25 !== -1
          && res13.data.pop_num_25 !== -1
            ? `${(
              Number((res12.data.pop_num_25 - res13.data.pop_num_25)
                    / res13.data.pop_num_25) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res12.data.pop_num_9
          && res13.data.pop_num_9
          && res12.data.pop_num_9 !== -1
          && res13.data.pop_num_9 !== -1
            ? `${(
              Number((res12.data.pop_num_9 - res13.data.pop_num_9)
                    / res13.data.pop_num_9) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res10.source_sum
          && res11.source_sum
          && res10.source_sum !== -1
          && res11.source_sum !== -1
            ? `${(
              Number((res10.source_sum - res11.source_sum) / res11.source_sum) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res10.percent
          && res11.percent
          && res10.percent !== -1
          && res11.percent !== -1
            ? `${(
              Number((res10.percent - res11.percent) / res11.percent) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res10.pop_sum
          && res11.pop_sum
          && res10.pop_sum !== -1
          && res11.pop_sum !== -1
            ? `${(
              Number((res10.pop_sum - res11.pop_sum) / res11.pop_sum) * 100
            ).toFixed(2)}%`
            : '无法比较'
        }`,
        `${
          res10.area && res11.area && res10.area !== -1 && res11.area !== -1
            ? `${(Number((res10.area - res11.area) / res11.area) * 100).toFixed(2)}%`
            : '无法比较'
        }`,
      ];
      nameList.forEach((i: string, index: number) => {
        bussinessList.push({
          name: i,
          data: valueList[index],
          lastvalue: compareList[index],
        });
      });
      option.datas.push({
        sheetData: bussinessList.reverse(),
        sheetName: '商圈分析',
        sheetFilter: ['name', 'data', 'lastvalue'],
        sheetHeader: ['分析名称', '数值', `较上${this.excelTitle}`],
        columnWidths: [15, 10, 10],
      });
      option.datas.push({
        sheetData: portraitAnalysisList,
        sheetName: '画像分析',
        sheetFilter: ['name', 'dimension', 'text', 'percent'],
        sheetHeader: ['属性名称', '维度', '名称', '百分比'],
        columnWidths: [10, 8, 8],
      });

      option.datas.push({
        sheetData:
          competitionRes?.data?.length && competitionRes.data[0].obj
            ? Object.values(competitionRes.data[0].obj).slice(0, 33)
            : [{ title: '暂无数据', rank: '暂无数据', rankList: '暂无数据' }],
        sheetName: '竞争分析',
        sheetFilter: ['title', 'rank', 'rankList'],
        sheetHeader: ['分析维度', '排名', `较上${this.excelTitle}`],
        columnWidths: [15, 8, 8],
      });
      const toExcel = new ExportJsonExcel(option); // new
      toExcel.saveExcel();
      this.setCacheExportData(exportKey, option);
      this.isDownloadExcel = false;
    });
  }
  async checkDetail(row: any): Promise<void> {
    this.isCheck = !this.isCheck;
    this.$stores.global.checkDetails(true);
    this.isDownloadPPT = false;
    this.$stores.global.checkDownloadPPT(false);
    this.loading = true;
    this.setDetail(row);
  }
  setDetail(row: any, isPPT = false) {
    this.$stores.global.reportName(row.reportName);
    const { firstDate, lastDate, dataDate } = row;
    const beforeDateObj = processBeforeDateByDuration(dataDate, this.dropType);
    const { dataDate: beforeDataDate, firstDate: beforeFristDate,
      lastDate: beforeLastDate, date: beforeDate } = beforeDateObj;
    const dayList = getDateAryByStartEnd(firstDate, lastDate);
    const lastDayList = getDateAryByStartEnd(beforeFristDate, beforeLastDate);
    const beforeTime = beforeDataDate;
    localStorage.setItem('reportDetail', JSON.stringify({ ...row, dayList, lastDayList, beforeTime, beforeDate, isPPT }));
    if (this.isCheck) {
      this.data1 = [];
    }
  }

  // 下载ppt
  async downloadPPT(row: any): Promise<void> {
    this.isDownloadPPT = true;
    this.$stores.global.checkDownloadPPT(true);
    this.setDetail(row, true);
  }
}
