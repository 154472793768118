





















































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import {
  flowAnalysis,
  consumeAbility,
  shoppingMallArrival,
} from '@/utils/echarts/echarts';
import * as Services from '@/services/api';
import { Vue, Component } from 'vue-property-decorator';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import {
  getMapCenter,
  initMap,
  drawMall,
  clearMall,
  processDataByStartEnd,
  zoomUp,
  zoomDown,
} from '@/components/map/map';
import { Grid } from '@/components/map/grid';
import gradientCard from '@/components/GradientCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { getContainer, getMax, getMin, groupAry } from '@/components';
import { PerAgePortraitSParams, GridParams } from '@/types/store';
import { mallRankListInfo } from '@/config/global';
import exportPPT from '../ppt/index';
import html2canvas from 'html2canvas';
@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    gradientCard,
  },
})
export default class ReportPage extends Vue {
  $dayjs: any;
  spinShow = true;
  mallMarker!: any;
  compareType = '周';
  tableWh = 400;
  table = [{}];
  res: any;
  data: any = [{}];
  map: any;
  collapseList = true;
  filterValue: number[] = [0, 100];
  dates = '';
  boundary = '';
  center = '';
  params!: GridParams;
  rowActive = 0;
  initZom = 13;
  step = 0.01;
  max = 100;
  min = 0;
  environmentColumns = [
    {
      title: '排名',
      key: 'rank',
      align: 'center',
    },
    {
      title: '分析维度',
      key: 'name',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '较上周',
      key: 'compare',
      slot: 'compare',
      align: 'center',
    },
    {
      key: 'type',
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      renderHeader: (a: any) => a('div', [
        a('Button', {
          domProps: {
            id: 'default',
            innerHTML: '<span>综合</span>',
          },
          style: {
            marginLeft: '5px',
          },
        }),
        a('Button', {
          domProps: {
            id: 'up',
            innerHTML: '上升',
          },
          style: {
            marginLeft: '5px',
          },
        }),
        a('Button', {
          domProps: {
            id: 'down',
            innerHTML: '下降',
          },
          style: {
            marginLeft: '5px',
          },
        }),
      ]),
    },
  ];
  environmentData: any = [];
  gridColor = [
    'rgba(43,50,153, .7)',
    'rgba(46,63,176, .7)',
    'rgba(50,85,199, .7)',
    'rgba(44,99,217, .7)',
    'rgba(43,115,224, .7)',
    'rgba(41,132,230, .7)',
    'rgba(41,145,230, .7)',
    'rgba(46,178,255, .7)',
    'rgba(91,201,255, .7)',
  ].reverse();
  gridColor2 = ['rgba(255,255,255,.1)'];
  grid: any;
  title = '本市覆盖排名';
  type = 0;
  text = '1平方公里';
  singleColor = 'blue';
  titleDisplay = true;
  gradientWidth = 22;
  gradientList = [
    {
      id: 1,
      color: this.gridColor[0],
      width: this.gradientWidth,
    },
    {
      id: 2,
      color: this.gridColor[1],
      width: this.gradientWidth,
    },
    {
      id: 3,
      color: this.gridColor[2],
      width: this.gradientWidth,
    },
    {
      id: 4,
      color: this.gridColor[3],
      width: this.gradientWidth,
    },
  ];
  gradientValue = [
    {
      id: 1,
      num: 1,
    },
    {
      id: 2,
      num: 2,
    },
    {
      id: 3,
      num: 3,
    },
  ];
  gridColorObj1 = {
    color: this.gridColor,
    top0Color: this.gridColor[0],
    top1Color: 'rgba(254,12,46, .6)',
    strokeStyle: 'rgba(255,255,255,.6)',
    lineWidth: 1,
    textColor: '#ffffff',
  };
  color = 'rgba(255,255,255,.1)';
  gridColorObj2 = {
    color: this.gridColor2,
    top0Color: this.color,
    top1Color: this.color,
    strokeStyle: 'rgba(80,128,215,1)',
    lineWidth: 2,
    textColor: 'black',
  };
  gridColorObj = this.gridColorObj1;

  titleInfoStatus = 1;
  style = 'color: #3c86fd;margin:0 6px;';
  titleInfo = `本市商圈覆盖面积<span style="${this.style}">0</span>平方公里，本市客流人数<span style="${this.style}">0</span>人`;
  mapHeight = '0';
  gridType = 0;
  gridClickType = 0;
  gridFunctionStatus = 1;
  gridFunctionOffStatus = 0;
  reportType: any;
  reportDetail: any;
  tips = true;
  arrivalNum: any = 0; // 客流总数
  xArrivalNum: any = 0; // 去重客流总数
  xArrivalName = '周去重客流总人数';
  arrivalNumLast: any = 0;
  numCompared: any = 0;
  xNumCompared: any = 0;
  peopleVisit: any = 0;
  visitCompared: any = 0;
  newTouristNum: any = 0;
  newNumCompared: any = 0;
  lastRatio: any = 0;
  colorList2 = ['#0062ff', '#ee5c3d'];
  trendOption = {};
  newTouristOption = {};
  localTouristOption = {};
  cityOption = {};
  shoppingMallArrival = {};
  maritalStatus = {};
  consumeAbility = {};
  lifeStage = {};
  childStatus = {};
  education = {};
  consumptionlevel = {};
  frequencyOfVisits = {};
  resturantOption={};
  vehicle = {};
  vehicle2 = {};
  housingPrice = {};
  restaurantLevel = {};
  restaurantConsume = {};
  arrival = {};
  phoneType = {};
  phonePrice = {};
  frequencyOfLeisure = {};
  frequencyOfSports = {};
  frequencyOfCar = {};
  ageConsumeDetails = {};
  ageConsumeDistribution = {};
  localTouristList = ['本地', '外地'];
  list: any = [];
  $getPdf: any;
  squareListInfo = [
    {
      topTitle: '9平方公里客流总数',
      topNum: 0,
      type: '周',
      topIconType: 'md-arrow-round-up',
      topRatio: '0%',
      topColor: 1,
      bottomTitle: '25平方公里客流总数',
      bottomNum: 0,
      bottomIconType: 'md-arrow-round-down',
      bottomRatio: '3.3%',
      bottomColor: 0,
    },
    {
      topTitle: '9平方公里客流总数占比',
      topNum: 0,
      type: '周',
      topIconType: 'md-arrow-round-up',
      topRatio: '0%',
      topColor: 1,
      bottomTitle: '25平方公里客流总数占比',
      bottomNum: 0,
      bottomIconType: 'md-arrow-round-down',
      bottomRatio: '3.3%',
      bottomColor: 0,
    },
    {
      topTitle: '9平方公里居住人口数',
      topNum: 0,
      type: '周',
      topIconType: 'md-arrow-round-up',
      topRatio: '0%',
      topColor: 1,
      bottomTitle: '25平方公里居住人口数',
      bottomNum: 0,
      bottomIconType: 'md-arrow-round-down',
      bottomRatio: '3.3%',
      bottomColor: 0,
    },
    {
      topTitle: '9平方公里客流渗透率',
      topNum: 0,
      type: '周',
      topIconType: 'md-arrow-round-up',
      topRatio: '0%',
      topColor: 1,
      bottomTitle: '25平方公里客流渗透率',
      bottomNum: 0,
      bottomIconType: 'md-arrow-round-down',
      bottomRatio: '3.3%',
      bottomColor: 0,
    },
  ];
  mallInfo = [
    {
      title: '商圈总面积',
      tipsInfo: '由于数据隐私风险，商圈内客流过低的区域网格未做可视化展示',
      num: 0,
      type: '周',
      iconType: 'md-arrow-round-up',
      ratio: '0',
      color: 1,
    },
    {
      title: '商圈总人口',
      tipsInfo: '商圈区域范围内,居住人口总数',
      num: 0,
      type: '周',
      iconType: 'md-arrow-round-up',
      ratio: '0',
      color: 1,
    },
    {
      title: '商圈客流人数',
      tipsInfo: '商圈区域范围内,总客流人数',
      num: 0,
      type: '周',
      iconType: 'md-arrow-round-up',
      ratio: '0%',
      color: 1,
    },
    {
      title: '商圈渗透率',
      tipsInfo: '商圈客流人数/商圈总人口数',
      num: 0,
      type: '周',
      iconType: 'md-arrow-round-up',
      ratio: '0%',
      color: 1,
    },
  ];
  isPPT = false;
  pptDatas: any = {}; // PPT导出需要的数据
  loading = true;

  get dataType(): string {
    return this.reportDetail.reportPage.dataType;
  }
  get excelTitle(): string {
    return this.reportDetail.reportPage.excelTitle;
  }

  async mounted(): Promise<void> {
    const rankDefault = document.getElementById('default');
    const rankUp = document.getElementById('up');
    const rankDown = document.getElementById('down');
    this.reportType = localStorage.getItem('reportDropType');
    this.reportDetail = JSON.parse(localStorage.getItem('reportDetail') as any);
    const { reportPage } = this.reportDetail;
    const { excelTitle, dataType } = reportPage;
    this.compareType = excelTitle;
    const names: any = {
      xw: '周去重',
      xm: '月去重',
      xh: '节假日去重',
      s: '月度去重累加',
      y: '月度去重累加',
    };
    this.xArrivalName = `${names[dataType]}客流总人数`;
    this.environmentColumns[2].title = `较上${excelTitle}`;
    this.isPPT = this.reportDetail.isPPT; // 当前页面是否是下载ppt
    if (this.isPPT) {
      this.loading = true;
    }
    Promise.all([
      this.touristTrend(),
      this.pageHandler(),
      this.financeProperty(),
      this.arrivalProperty(),
      this.businessInfo(),
      this.squareInfo(),
    ]).then(async () => {
      if (this.isPPT) {
        setTimeout(async () => {
          const post: any = document.getElementById('map-container');
          const width = post.offsetWidth;
          const height = post.offsetHeight;
          const canvas = await html2canvas(post, {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            useCORS: true,
            allowTaint: false,
            width,
            height,
            logging: false,
            scale: 2,
          });
          const url = canvas.toDataURL('image/png');
          this.pptDatas = {
            mallname: this.$store.state.mall.mallName,
            mallId: this.$store.state.mall.mallId,
            duration: this.reportDetail.duration,
            name: this.reportDetail.name,
            type: this.reportDetail.type,
            arrivalNum: this.arrivalNum,
            xArrivalNum: this.xArrivalNum,
            xArrivalName: this.xArrivalName,
            numCompared: this.numCompared,
            xNumCompared: this.xNumCompared,
            peopleVisit: this.peopleVisit,
            visitCompared: this.visitCompared,
            newTouristNum: this.newTouristNum,
            newNumCompared: this.newNumCompared,
            lastRatio: this.lastRatio,
            mallInfo: {
              data: this.mallInfo.map((el: any) => ({
                name: el.title,
                num: el.num,
                ratio: el.ratio,
              })),
              url,
            },
            ...this.pptDatas,
          };
          await exportPPT(this.pptDatas);
          this.$stores.global.checkDownloadPPT(false);
          this.loading = true;
        }, 0);
      }
    });
    this.mapHeight = `${getContainer()}px`;
    this.map = initMap('map-container', this.initZom, this.center);
    const { pageConfig } = this.$store.state;
    this.filterValue = pageConfig.businessFilterValue;
    this.pageHandlers();
    this.rankList();
    (rankDefault as any).style = 'background-color:blue';
    (rankDefault as any).style.color = '#fff';
    (rankDefault as any).addEventListener('click', () => {
      (rankDefault as any).style.backgroundColor = 'blue';
      (rankDefault as any).style.color = '#fff';
      (rankUp as any).style.backgroundColor = '#fff';
      (rankUp as any).style.color = '#515a6e';
      (rankDown as any).style.backgroundColor = '#fff';
      (rankDown as any).style.color = '#515a6e';
      this.orderList(1);
    });
    (rankUp as any).addEventListener('click', () => {
      (rankUp as any).style.backgroundColor = 'blue';
      (rankUp as any).style.color = '#fff';
      (rankDefault as any).style.backgroundColor = '#fff';
      (rankDefault as any).style.color = '#515a6e';
      (rankDown as any).style.backgroundColor = '#fff';
      (rankDown as any).style.color = '#515a6e';
      this.orderList(2);
    });
    (rankDown as any).addEventListener('click', () => {
      (rankDown as any).style.backgroundColor = 'blue';
      (rankDown as any).style.color = '#fff';
      (rankUp as any).style.backgroundColor = '#fff';
      (rankUp as any).style.color = '#515a6e';
      (rankDefault as any).style.backgroundColor = '#fff';
      (rankDefault as any).style.color = '#515a6e';
      this.orderList(3);
    });
  }
  pageHandlers(): void {
    this.spinShow = true;
    setTimeout(() => {
      this.mapPageHandler(this.gridType, this.gridColorObj);
    }, 0);
  }

  getTableByType(): any {
    const table = [
      {
        title: '排名',
        key: 'cover_rank',
        sortable: true,
      },
      {
        title: '输送客流',
        key: 'num',
        sortable: true,
      },
      {
        title: '区域人口',
        key: 'pop',
        sortable: true,
      },
      {
        title: '渗透率(%)',
        key: 'cover',
        sortable: true,
      },
    ];
    return table;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async mapPageHandler(type: number, gridColorObj?: any): Promise<void> {
    const { mall, pageConfig } = this.$store.state;
    const { boundary, center, dates } = mall;
    const {  dataDate } = this.reportDetail;
    const mallId = 'mall_id';
    this.filterValue = pageConfig.businessFilterValue;

    this.boundary = boundary;
    this.center = center;
    this.dates = dates;
    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      this.map.panBy(198, 0);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center);
    }
    this.params = {
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      duration: this.dataType,
      start_percent: 0,
      end_percent: 100,
      type: 1,
    };
    this.table = this.getTableByType();
    this.res = await this.getGridDepressionData(this.params);
    this.data = this.res.data;
    if (!this.data) {
      this.data = [];
    }
    if (type === 1) {
      const data = this.data.slice(1);
      const data0 = this.data[0];
      this.data = processDataByStartEnd(
        this.filterValue[0],
        this.filterValue[1],
        data,
      );
      this.data.unshift(data0);
    }
    this.max = getMax(this.data, 'cover');
    this.min = getMin(this.data, 'cover');
    const area = this.data.length;
    const { sum } = this.res;
    this.titleInfo = `本市商圈覆盖面积<span style="${this.style}">${area}</span>平方公里，本市客流人数<span style="${this.style}">${sum}</span>人`;
    this.data.forEach((item: any, index: number) => {
      (this.data[index] as any).index = index;
    });
    this.grid = new (Grid as any)({
      map: this.map,
      data: this.data,
      color: gridColorObj.color,
      weightFiled: 'cover',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: gridColorObj.top0Color,
      top1Color: gridColorObj.top1Color,
      strokeStyle: gridColorObj.strokeStyle,
      lineWidth: gridColorObj.lineWidth,
      textColor: gridColorObj.textColor,
    });

    this.grid.gridClick((item: any) => {
      const { cover_rank: coverRank } = item;
      this.rowActive = coverRank;
      (this.$refs.tables as any).tableClickHandler(item, 1);
    });
    this.grid.gridMove();

    this.gradientList = [];
    let index = 0;
    this.gridColor.forEach((item: any) => {
      index = index + 1;
      const obj = {
        id: index,
        color: item,
        width: this.gradientWidth,
      };
      this.gradientList.push(obj);
    });
    const valueCenter = Math.round((this.max / 2) * 100) / 100;
    this.gradientValue = [
      {
        id: 1,
        num: this.min,
      },
      {
        id: 2,
        num: valueCenter,
      },
      {
        id: 3,
        num: this.max,
      },
    ];
    this.spinShow = false;
  }
  async getGridDepressionData(params: GridParams): Promise<any> {
    const data = await Services.gridDepressionData(params);
    return data;
  }

  sliderChange(val: number[]): void {
    // 进度条数据变化返回
    this.filterValue = val;
    this.$stores.pageConfig.setBusinessFilterValue(val);
    this.gridType = 1;
    this.pageHandlers();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  rowClick(val: any): void {
    // 列表点击事件返回数据
    if (this.rowActive === val.index) {
      return;
    }
    this.rowActive = val.index;
    const center = this.grid.getGridCenter(val);
    this.grid.polygonHandler(val);
    const { lat, lng } = center;
    const centers = new (window as any).qq.maps.LatLng(lat, lng);
    this.map.setCenter(centers);
    this.map.panBy(198, 0);
  }
  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  mallPostionClickHandler(): void {
    const center = getMapCenter(this.center);
    this.map.panTo(center);
    this.map.panBy(198, 0);
  }

  drawFlowNumEcharts({ times, percents, newPercents, newNums, oldNums, flows, nums, dataObj,
    dataZoomStatus, barWidth }: any): void{
    const legendData = [
      {
        name: '人次',
      },
      {
        name: '人数',
      },
      {
        name: '人均到访频次',
      },
    ];
    const legendData2 = [
      {
        name: '新客人数',
      },
      {
        name: '老客人数',
      },
      {
        name: '新客占比',
      },
    ];
    this.trendOption = flowAnalysis(
      times,
      percents,
      flows,
      nums,
      legendData,
      dataObj,
      1,
      1,
      dataZoomStatus,
      barWidth,
      '2',
    );
    this.newTouristOption = flowAnalysis(
      times,
      newPercents,
      newNums,
      oldNums,
      legendData2,
      dataObj,
      0,
      0,
      dataZoomStatus,
      barWidth,
    );
  }

  gridFunctionClickHandler(): void {
    if (this.gridClickType === 0) {
      this.gridFunctionStatus = 0;
      this.gridFunctionOffStatus = 1;
      this.gridClickType = 1;
      this.gridColorObj = this.gridColorObj2;
    } else {
      this.gridFunctionStatus = 1;
      this.gridFunctionOffStatus = 0;
      this.gridClickType = 0;
      this.gridColorObj = this.gridColorObj1;
    }
    this.grid = new (Grid as any)({
      map: this.map,
      data: this.data,
      color: this.gridColorObj.color,
      weightFiled: 'cover',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: this.gridColorObj.top0Color,
      top1Color: this.gridColorObj.top1Color,
      strokeStyle: this.gridColorObj.strokeStyle,
      lineWidth: this.gridColorObj.lineWidth,
      textColor: this.gridColorObj.textColor,
    });
    this.grid.gridClick((item: any) => {
      const { cover_rank: coverRank } = item;
      this.rowActive = coverRank;
      (this.$refs.tables as any).tableClickHandler(item, 1);
    });
    this.grid.gridMove();
    const item = this.data[this.rowActive];
    setTimeout(() => {
      this.grid.polygonHandler(item);
    }, 0);
  }
  async touristTrend(): Promise<void> {
    const { dataDate, beforeTime, dayList, lastDayList, duration } = this.reportDetail;
    const mallId = 'mall_id';
    const dateAry = 'date_ary';
    const dataZoomStatus = !!(duration === 'y' || duration === 's');
    const barWidth = duration === 'y' || duration === 's' || duration === 'm' ? 'auto' : '25';
    const dayLists = groupAry(dayList, 60);
    const results = await Promise.all(dayLists.map(async (item: string[]) => {
      const params = {
        [mallId]: this.$store.state.mall.mallId,
        [dateAry]: item,
        duration: 'd',
        date_start: item[0],
        date_end: item[item.length - 1],
      };
      const result = await Services.passengerFlowData(params);
      return result;
    }));
    const times: Array<string> = [];
    const flows: Array<string> = [];
    const newNums: Array<string> = [];
    const newPercents: Array<string> = [];
    const nums: Array<string> = [];
    const percents: Array<string> = [];
    const oldNums: Array<string> = [];
    const dataObj: any = {};
    let sumNum = 0;
    let sumFlow = 0;
    results.forEach((res: any) => {
      const {
        times: times2,
        percents: percents2,
        flows: flows2,
        nums: nums2,
        newPercents: newPercents2,
        newNums: newNums2,
        oldNums: oldNums2,
        dataObj: dataObj2,
        sumObj,
      } = res;
      times.push(...times2);
      percents.push(...percents2);
      newPercents.push(...newPercents2);
      newNums.push(...newNums2);
      oldNums.push(...oldNums2);
      nums.push(...nums2);
      flows.push(...flows2);
      Object.assign(dataObj, dataObj2);
      const { num, flow } = sumObj;
      sumNum += num;
      sumFlow += flow;
    });
    this.arrivalNum = sumNum;
    this.peopleVisit = sumFlow;
    if (this.isPPT) {
      this.pptDatas.touristTrend = {
        times,
        flows,
        newNums,
        newPercents,
        nums,
        percents,
        oldNums,
        dataObj,
      };
    }
    this.drawFlowNumEcharts({
      times, percents, newPercents, newNums, oldNums, flows, nums, dataObj, dataZoomStatus, barWidth,
    });

    // 获取上一个月的数据，较上月
    const lastDayLists = groupAry(lastDayList, 60);
    const results1 = await Promise.all(lastDayLists.map(async (item: string[]) => {
      const params = {
        [mallId]: this.$store.state.mall.mallId,
        [dateAry]: item,
        duration: 'd',
        date_start: item[0],
        date_end: item[item.length - 1],
      };
      const result = await Services.passengerFlowData(params, false);
      return result;
    }));
    let sumNum1 = 0;
    let sumFlow1 = 0;
    results1.forEach((res: any) => {
      const { num, flow } = res.sumObj;
      sumNum1 += num;
      sumFlow1 += flow;
    });
    const numCompared = (((this.arrivalNum - sumNum1) / sumNum1) * 100).toFixed(2);
    this.numCompared = isNaN(this.arrivalNum - sumNum1) ? '无法比较' : numCompared;
    const visitCompared = (((this.peopleVisit - sumFlow1) / sumFlow1) * 100).toFixed(2);
    this.visitCompared = isNaN(this.peopleVisit - sumFlow1) ? '无法比较' : visitCompared;
    this.arrivalNumLast = sumNum1;

    const { dataType } = this;
    // if (this.dataType === 'xw') {
    //   dataType = 'w';
    // } else if (this.dataType === 'xm') {
    //   dataType = 'm';
    // }
    // 上周
    const res = await Services.getMallRankDataBasesMallRankingSvc({
      [mallId]: this.$store.state.mall.mallId,
      duration: dataType,
      type: '301',
      property: '1',
      subtype: '1',
      date: beforeTime,
    }, false);
    // 本周
    const res1 = await Services.getMallRankDataBasesMallRankingSvc({
      [mallId]: this.$store.state.mall.mallId,
      duration: dataType,
      type: '301',
      property: '1',
      subtype: '1',
      date: dataDate,
    });
    // // 上周
    // const res2 = await Services.getMallRankDataBasesMallRankingSvc({
    //   [mallId]: this.$store.state.mall.mallId,
    //   duration: `${this.reportType}`,
    //   type: '301',
    //   property: '2',
    //   subtype: '1',
    //   date: beforeTime,
    // });
    // // 本周
    // const res3 = await Services.getMallRankDataBasesMallRankingSvc({
    //   [mallId]: this.$store.state.mall.mallId,
    //   duration: `${this.reportType}`,
    //   type: '301',
    //   property: '2',
    //   subtype: '1',
    //   date: dataDate,
    // });
    // this.visitCompared = res2?.data !== -1 && res3?.data !== -1
    //   ? `${(((res3.data - res2.data) / res2.data) * 100).toFixed(2)}`
    //   : '无法比较';
    // this.peopleVisit = res3?.data !== -1 ? res3.data : '暂无数据';
    this.xArrivalNum = res1?.data !== -1 ? res1.data : '暂无数据';
    this.xNumCompared = res1?.data !== -1 && res?.data !== -1
      ? `${(((res1.data - res.data) / res.data) * 100).toFixed(2)}`
      : '无法比较';
    // 本周
    const res5 = await Services.getMallPortaitsByMallIdSSvc({
      [mallId]: this.$store.state.mall.mallId,
      duration: 'd',
      type: '1',
      property: '50',
      [dateAry]: dayList,
    });
    // 上周
    const res6 = await Services.getMallPortaitsByMallIdSSvc({
      [mallId]: this.$store.state.mall.mallId,
      duration: 'd',
      type: '1',
      property: '50',
      [dateAry]: lastDayList,
    }, false);
    this.newTouristNum = res5?.s_new_num !== -1 ? res5.s_new_num : '暂无数据';
    this.newNumCompared = res5?.s_new_num !== -1 && res6?.s_new_num !== -1
      ? `${(
        ((res5?.s_new_num - res6?.s_new_num) / res6?.s_new_num)
            * 100
      ).toFixed(2)}`
      : '无法比较';
    this.lastRatio = res6?.s_new_num !== -1 && !isNaN(this.arrivalNumLast) && this.arrivalNumLast !== 0
      ? res6.s_new_num / this.arrivalNumLast
      : '无法比较';
    // 本外地客流
    const res7 = await Services.getLocaltiyAndOutsidePassengerFlowSSvc({
      [mallId]: this.$store.state.mall.mallId,
      [dateAry]: [dataDate],
      duration: this.dataType,
    });
    this.localTouristOption = this.pieCardDisplay(
      {
        10: {
          percent: (Object.values(res7.data) as any)[0].localtiy_ratio,
          title: '客流趋势',
          type: '本地',
        },
        11: {
          percent: (Object.values(res7.data) as any)[0].outside_ratio,
          title: '客流趋势',
          type: '外地',
        },
      },
      this.localTouristList,
      '客流趋势',
      this.colorList2,
    );
    const res8 = await Services.passengerFlowTops({
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      duration: this.dataType,
    });
    // 客群城市分布
    this.cityOption = this.lineCardDisplay(
      res8.datas,
      res8.citys,
      '外地客群城市分布',
    );
    // ppt取数据
    if (this.isPPT) {
      this.pptDatas.localTouristOption = {
        labels: ['本地', '外地'],
        datas: [
          (Object.values(res7.data) as any)[0].localtiy_ratio,
          (Object.values(res7.data) as any)[0].outside_ratio,
        ],
      };
      this.pptDatas.cityOption = {
        labels: res8.citys,
        datas: res8.datas.map((el: { percent: number }) => el.percent),
      };
    }
  }
  lineCardDisplay(data: any, list: any, title: string): any {
    const mallValue = Object.values(data);
    mallValue.forEach((i) => {
      Object.assign(i, {
        title,
      });
    });
    return shoppingMallArrival(
      list,
      mallValue.map(i => (i as any).percent),
      title,
    );
  }
  pieCardDisplay(
    data: any,
    list: any,
    title: string,
    colorList: string[],
  ): any {
    const consumeValue = Object.values(data);
    consumeValue.forEach((i, ind) => {
      Object.assign(i, {
        title,
        type: list[ind],
      });
    });
    return consumeAbility(
      list,
      consumeValue.map(i => (i as any).percent),
      title,
      '',
      colorList,
    );
  }
  async pageHandler(): Promise<void> {
    const { dataDate } = this.reportDetail;
    const mallId = 'mall_id';
    // 年龄分布
    const jType = 'j_type';
    const sumType = 'sum_type';
    const PerAgePortraitSParams: PerAgePortraitSParams = {
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      duration: this.dataType,
      type: 102,
      [jType]: 1,
      [sumType]: 0,
    };
    const title = '年龄';
    const agePortraitRes = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title,
      '',
      shoppingMallArrival,
      [''],
    );
    this.shoppingMallArrival = agePortraitRes.option;
    this.setPPTDatas('agePortraitRes', agePortraitRes);
    // 性别
    PerAgePortraitSParams.type = 101;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry = ['#0062ff', '#ee5c3d'];
    const title1 = '性别';
    const title1s = '男女性别占比';
    const sexPortraitRes = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title1,
      title1s,
      consumeAbility,
      colorAry,
    );
    this.consumeAbility = sexPortraitRes.option;
    this.setPPTDatas('sexPortraitRes', sexPortraitRes);
    // 人生阶段
    PerAgePortraitSParams.type = 106;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry2 = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title2 = '人生阶段';
    const title2s = '人生阶段占比';
    const portraitRes1 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title2s,
      consumeAbility,
      colorAry2,
    );
    this.lifeStage = portraitRes1.option;
    this.setPPTDatas('lifeStage', portraitRes1);
    // 婚姻状态
    PerAgePortraitSParams.type = 105;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry3 = colorAry;
    const title3 = '婚姻状态';
    const title3s = '婚姻状态占比';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      consumeAbility,
      colorAry3,
    );
    this.maritalStatus = portraitRes2.option;
    this.setPPTDatas('maritalStatus', portraitRes2);
    // 子女状态
    PerAgePortraitSParams.type = 104;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry4 = colorAry2;
    const title4 = '子女状态';
    const title4s = '子女年龄占比';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title4s,
      consumeAbility,
      colorAry4,
    );

    this.childStatus = portraitRes4.option;
    this.setPPTDatas('childStatus', portraitRes4);

    // 学历
    PerAgePortraitSParams.type = 103;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title5 = '学历';

    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title5,
      title1s,
      shoppingMallArrival,
      [''],
    );
    portraitRes5.datas.reverse();
    this.education = portraitRes5.option;
    this.setPPTDatas('education', portraitRes5);
  }
  async financeProperty() {
    const { dataDate } = this.reportDetail;
    const mallId = 'mall_id';
    const PerAgePortraitSParams: PerAgePortraitSParams = {
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      duration: this.dataType,
      type: 102,
      j_type: 1,
      sum_type: 0,
    };

    PerAgePortraitSParams.type = 107;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title1 = '消费水平';
    const title1s = '消费水平占比';
    const portraitRes1 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title1,
      title1s,
      consumeAbility,
      colorAry,
    );
    this.consumptionlevel = portraitRes1.option;
    this.setPPTDatas('consumptionlevel', portraitRes1);

    // 商场到访频次
    PerAgePortraitSParams.type = 108;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title2 = '商场到访频次';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfVisits = portraitRes2.option;
    this.setPPTDatas('frequencyOfVisits', portraitRes2);

    // 是否有车
    PerAgePortraitSParams.type = 112;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title3 = '是否有车';
    const title3s = '';
    const portraitRes3 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      consumeAbility,
      colorAry,
    );

    this.vehicle = portraitRes3.option;
    this.setPPTDatas('vehicle', portraitRes3);

    // 居住社区房价
    PerAgePortraitSParams.type = 111;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title4 = '居住社区房价';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.housingPrice = portraitRes4.option;
    this.setPPTDatas('housingPrice', portraitRes4);

    // 餐饮消费水平
    PerAgePortraitSParams.type = 110;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '餐饮消费水平',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.restaurantLevel = portraitRes5.option;
    this.setPPTDatas('restaurantLevel', portraitRes5);

    // 餐饮消费频次
    PerAgePortraitSParams.type = 109;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes6 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '餐饮消费频次',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.restaurantConsume = portraitRes6.option;
    this.setPPTDatas('restaurantConsume', portraitRes6);

    // 到访4s店品牌
    const arrivalParams = {
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      duration: this.dataType,
      type: 114,
      order_by: 'num',
      top_start: '0',
      top_end: '10',
    };
    const portraitRes7 = await this.arrivalAgePortrait(
      arrivalParams,
      '到访4s店品牌',
      title1s,
      shoppingMallArrival,
      [''],
    );
    // percent;
    this.arrival = portraitRes7.option;
    this.setPPTDatas('arrival', portraitRes7);
    // 手机品牌
    PerAgePortraitSParams.type = 115;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes8 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '手机品牌',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.phoneType = portraitRes8.option;
    this.setPPTDatas('phoneType', portraitRes8);

    // 手机价格
    PerAgePortraitSParams.type = 116;
    PerAgePortraitSParams.j_type = 2;
    PerAgePortraitSParams.sum_type = 0;
    const portraitRes9 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      '手机价格',
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.phonePrice = portraitRes9.option;
    this.setPPTDatas('phonePrice', portraitRes9);
  }
  async arrivalProperty() {
    const { dataDate } = this.reportDetail;
    const mallId = 'mall_id';
    const PerAgePortraitSParams: PerAgePortraitSParams = {
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      duration: this.dataType,
      type: 501,
      j_type: 0,
      sort: true,
      sum_type: 1,
    };

    const colorAry = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title1s = '消费水平占比';
    // 餐厅
    PerAgePortraitSParams.type = 501;
    const title2 = '到访占比';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.resturantOption = portraitRes2.option;
    this.setPPTDatas('resturantOption', portraitRes2);

    // 商场
    PerAgePortraitSParams.type = 502;
    const title3 = '到访占比';
    const title3s = '';
    const portraitRes3 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      shoppingMallArrival,
      colorAry,
    );
    this.vehicle2 = portraitRes3.option;
    this.setPPTDatas('vehicle2', portraitRes3);

    // 餐厅
    PerAgePortraitSParams.type = 503;
    const title4 = '到访占比';
    const title4s = '';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title4s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfLeisure = portraitRes4.option;
    this.setPPTDatas('frequencyOfLeisure', portraitRes4);

    // 运动健身
    PerAgePortraitSParams.type = 504;
    const title5 = '到访占比';
    const title5s = '';
    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title5,
      title5s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfSports = portraitRes5.option;
    this.setPPTDatas('frequencyOfSports', portraitRes5);

    // car
    PerAgePortraitSParams.type = 505;
    const title6 = '到访占比';
    const title6s = '';
    const portraitRes6 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title6,
      title6s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfCar = portraitRes6.option;
    this.setPPTDatas('frequencyOfCar', portraitRes6);
  }
  async arrivalAgePortrait(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    params: any,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await Services.getGridPerAgePortraitInterceptS(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);
    return {
      option,
      datas,
      data,
    };
  }
  async getPerAgePortrait(
    params: PerAgePortraitSParams,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await Services.portraitByMallId(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);
    return {
      option,
      datas,
      data,
    };
  }
  setPPTDatas(key: string, data: any): void{
    const allDatasSort = data.datas.sort((a: any, b: any) => b.percent - a.percent);
    if (this.isPPT) {
      this.pptDatas[key] = {
        labels: data.data.texts,
        datas: data.data.percents,
        num1Name: allDatasSort.length > 0 ? allDatasSort[0].text : '',
        num1: allDatasSort.length > 0 ? allDatasSort[0].percent : -1,
        num2Name: allDatasSort.length > 1 ? allDatasSort[1].text : '',
        num2: allDatasSort.length > 1 ? allDatasSort[1].percent : -1,
        num3Name: allDatasSort.length > 2 ? allDatasSort[2].text : '',
        num3: allDatasSort.length > 2 ? allDatasSort[2].percent : -1,
      };
    }
  }
  // 商圈覆盖
  async businessInfo(): Promise<void> {
    const { dataDate, beforeTime } = this.reportDetail;
    const mallId = 'mall_id';
    const startPercent = 'start_percent';
    const endPercent = 'end_percent';
    // 本周
    const res = await Services.getGridBussinessCircleWeekMonthSvc({
      duration: this.dataType,
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
      [startPercent]: 0,
      [endPercent]: 100,
      property: '2',
      type: '1',
    });

    // 上周
    const res1 = await Services.getGridBussinessCircleWeekMonthSvc({
      duration: this.dataType,
      [mallId]: this.$store.state.mall.mallId,
      date: beforeTime,
      [startPercent]: 0,
      [endPercent]: 100,
      property: '2',
      type: '1',
    }, false);
    this.mallInfo.forEach((i: any, index: number) => {
      switch (index) {
        case 0:
          i.num = res?.area ? res.area : '暂无数据';
          i.type = this.excelTitle;
          i.ratio = res?.area && res1?.area
            ? `${(((res.area - res1.area) / res1.area) * 100).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.iconType = res?.area && res1?.area
            ? res.area - res1.area > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.color = res?.area && res1?.area && res.area - res1.area < 0 ? 0 : 1;
          return;
        case 1:
          i.num = res?.pop_sum ? res.pop_sum : '暂无数据';
          i.type = this.excelTitle;
          i.ratio = res?.pop_sum && res1?.pop_sum
            ? `${(
              ((res.pop_sum - res1.pop_sum) / res1.pop_sum)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.iconType = res?.pop_sum && res1?.pop_sum
            ? res.pop_sum - res1.pop_sum > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.color = res?.pop_sum && res1?.pop_sum && res.pop_sum - res1.pop_sum < 0
            ? 0
            : 1;
          return;
        case 2:
          i.num = res?.source_sum ? res.source_sum : '暂无数据';
          i.type = this.excelTitle;
          i.ratio = res?.source_sum && res1?.source_sum
            ? `${(
              ((res.source_sum - res1.source_sum) / res1.source_sum)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.iconType = res?.source_sum && res1?.source_sum
            ? res.source_sum - res1.source_sum > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.color = res?.source_sum
            && res1?.source_sum
            && res.source_sum - res1.source_sum < 0
            ? 0
            : 1;
          return;
        case 3:
          i.num = res?.percent ? `${res.percent}%` : '暂无数据';
          i.type = this.excelTitle;
          i.ratio = res?.percent && res1?.percent
            ? `${(
              ((res.percent - res1.percent) / res1.percent)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.iconType = res?.percent && res1?.percent
            ? res.percent - res1.percent > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.color = res?.percent && res1?.percent && res.percent - res1.percent < 0
            ? 0
            : 1;
          return;
      }
    });
  }
  async squareInfo(): Promise<void> {
    const { dataDate, beforeTime } = this.reportDetail;
    // const { dataDate } = this.reportDetail;
    const mallId = 'mall_id';
    // 本周商圈数据
    const res = await Services.getMallRankingListWeekMonthSvc({
      duration: this.dataType,
      [mallId]: this.$store.state.mall.mallId,
      date: dataDate,
    });
    // 上周商圈数据
    const res1 = await Services.getMallRankingListWeekMonthSvc({
      duration: this.dataType,
      [mallId]: this.$store.state.mall.mallId,
      // date: dataDate,
      date: beforeTime,
    }, false);
    this.squareListInfo.forEach((i: any, index: number) => {
      switch (index) {
        case 0:
          i.topNum = res?.data?.pop_num_9 ? res.data.pop_num_9 : '暂无数据';
          i.type = this.excelTitle;
          i.topRatio = (res?.data?.pop_num_9 && res1?.data?.pop_num_9) > 0
            ? `${(
              ((res.data.pop_num_9 - res1.data.pop_num_9)
                    / res1.data.pop_num_9)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.topIconType = res?.data?.pop_num_9 && res1?.data?.pop_num_9
            ? res.data.pop_num_9 - res1.data.pop_num_9 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.topColor = res?.data?.pop_num_9
            && res1?.data?.pop_num_9
            && res.data.pop_num_9 - res1.data.pop_num_9 < 0
            ? 0
            : 1;
          i.bottomNum = res?.data?.pop_num_25
            ? res.data.pop_num_25
            : '暂无数据';
          i.bottomRatio = (res?.data?.pop_num_25 && res1?.data?.pop_num_25) > 0
            ? `${(
              ((res.data.pop_num_25 - res1.data.pop_num_25)
                    / res1.data.pop_num_25)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.bottomIconType = res?.data?.pop_num_25 && res1?.data?.pop_num_25
            ? res.data.pop_num_25 - res1.data.pop_num_25 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.bottomColor = res?.data?.pop_num_25
            && res1?.data?.pop_num_25
            && res.data.pop_num_25 - res1.data.pop_num_25 < 0
            ? 0
            : 1;
          return;
        case 1:
          i.topNum = res?.data?.pop_ratio_9
            ? `${res.data.pop_ratio_9}%`
            : '暂无数据';
          i.type = this.excelTitle;
          i.topRatio = (res?.data?.pop_ratio_9 && res1?.data?.pop_ratio_9) > 0
            ? `${(
              ((res.data.pop_ratio_9 - res1.data.pop_ratio_9)
                    / res1.data.pop_ratio_9)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.topIconType = res?.data?.pop_ratio_9 && res1?.data?.pop_ratio_9
            ? res.data.pop_ratio_9 - res1.data.pop_ratio_9 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.topColor = res?.data?.pop_ratio_9
            && res1?.data?.pop_ratio_9
            && res.data.pop_ratio_9 - res1.data.pop_ratio_9 < 0
            ? 0
            : 1;
          i.bottomNum = res?.data?.pop_ratio_25
            ? `${res.data.pop_ratio_25}%`
            : '暂无数据';
          i.bottomRatio = (res?.data?.pop_ratio_25 && res1?.data?.pop_ratio_25) > 0
            ? `${(
              ((res.data.pop_ratio_25 - res1.data.pop_ratio_25)
                    / res1.data.pop_ratio_25)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.bottomIconType = res?.data?.pop_ratio_25 && res1?.data?.pop_ratio_25
            ? res.data.pop_ratio_25 - res1.data.pop_ratio_25 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.bottomColor = res?.data?.pop_ratio_25
            && res1?.data?.pop_ratio_25
            && res.data.pop_ratio_25 - res1.data.pop_ratio_25 < 0
            ? 0
            : 1;
          return;
        case 2:
          i.topNum = res?.data?.pop_base_9 ? res.data.pop_base_9 : '暂无数据';
          i.type = this.excelTitle;
          i.topRatio = (res?.data?.pop_base_9 && res1?.data?.pop_base_9) > 0
            ? `${(
              ((res.data.pop_base_9 - res1.data.pop_base_9)
                    / res1.data.pop_base_9)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.topIconType = res?.data?.pop_base_9 && res1?.data?.pop_base_9
            ? res.data.pop_base_9 - res1.data.pop_base_9 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.topColor = res?.data?.pop_base_9
            && res1?.data?.pop_base_9
            && res.data.pop_base_9 - res1.data.pop_base_9 < 0
            ? 0
            : 1;
          i.bottomNum = res?.data?.pop_base_25
            ? res.data.pop_base_25
            : '暂无数据';
          i.bottomRatio = (res?.data?.pop_base_25 && res1?.data?.pop_base_25) > 0
            ? `${(
              ((res.data.pop_base_25 - res1.data.pop_base_25)
                    / res1.data.pop_base_25)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.bottomIconType = res?.data?.pop_base_25 && res1?.data?.pop_base_25
            ? res.data.pop_base_25 - res1.data.pop_base_25 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.bottomColor = res?.data?.pop_base_25
            && res1?.data?.pop_base_25
            && res.data.pop_base_25 - res1.data.pop_base_25 < 0
            ? 0
            : 1;
          return;
        case 3:
          i.topNum = res?.data?.percent_9
            ? `${res.data.percent_9}%`
            : '暂无数据';
          i.type = this.excelTitle;
          i.topRatio = (res?.data?.percent_9 && res1?.data?.percent_9) > 0
            ? `${(
              ((res.data.percent_9 - res1.data.percent_9)
                    / res1.data.percent_9)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.topIconType = res?.data?.percent_9 && res1?.data?.percent_9
            ? res.data.percent_9 - res1.data.percent_9 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.topColor = res?.data?.percent_9
            && res1?.data?.percent_9
            && res.data.percent_9 - res1.data.percent_9 < 0
            ? 0
            : 1;
          i.bottomNum = res?.data?.percent_25
            ? `${res.data.percent_25}%`
            : '暂无数据';
          i.bottomRatio = (res?.data?.percent_25 && res1?.data?.percent_25) > 0
            ? `${(
              ((res.data.percent_25 - res1.data.percent_25)
                    / res1.data.percent_25)
                  * 100
            ).toFixed(2)}%`
            : '无法比较';
          // eslint-disable-next-line no-nested-ternary
          i.bottomIconType = res?.data?.percent_25 && res1?.data?.percent_25
            ? res.data.percent_25 - res1.data.percent_25 > 0
              ? 'md-arrow-round-up'
              : 'md-arrow-round-down'
            : '无法比较';
          i.bottomColor = res?.data?.percent_25
            && res1?.data?.percent_25
            && res.data.percent_25 - res1.data.percent_25 < 0
            ? 0
            : 1;
          return;
      }
    });
  }
  // 对比
  async rankList() {
    const { beforeTime, dataDate } = this.reportDetail;
    const mallId = 'mall_id';
    // 本周
    const competitionRes = await Services.getMallRankDataByMallIdSvc({
      [mallId]: this.$store.state.mall.mallId,
      start: 0,
      end: 100,
      duration: this.dataType,
      date: dataDate,
    });
    // 上周
    const competitionRes1 = await Services.getMallRankDataByMallIdSvc({
      [mallId]: this.$store.state.mall.mallId,
      start: 0,
      end: 100,
      duration: this.dataType,
      date: beforeTime,
    }, false);
    mallRankListInfo.forEach((i: any) => {
      this.environmentData.push({
        rank: '暂无数据',
        name: i.name,
        compare: '暂无数据',
      });
    });
    if (competitionRes?.data[0]?.obj) {
      Object.values(competitionRes.data[0].obj)
        .slice(0, 33)
        .forEach((i: any, index: number) => {
          this.environmentData[index].rank = i.rank;
        });
      if (competitionRes1?.data[0]?.obj) {
        Object.values(competitionRes1.data[0].obj)
          .slice(0, 33)
          .forEach((i: any, index: number) => {
            this.environmentData[index].compare = i.rank - this.environmentData[index].rank;
          });
      }
    }
    this.list = this.environmentData;
  }
  orderList(type: number): void {
    if (type === 1) {
      this.environmentData = this.list;
    } else if (type === 2) {
      this.environmentData = this.list.filter((i: any) => i.compare > 0);
    } else {
      this.environmentData = this.list.filter((i: any) => i.compare <= 0);
    }
  }
}
