






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { getReportType } from '..';

@Component({
  components: {},
})
export default class ReportDropdown extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: '',
  }) list !: any;

  text = this.list[0].name;
  beforeDropdown = '';
  created(): void{
    const texts = getReportType();
    this.text = texts;
    this.dropdownChange(this.text);
    this.beforeDropdown = this.text;
  }
  dropdownChange(e: string): void{
    if (this.beforeDropdown === e) return;
    let params = this.list[0];
    this.list.forEach((item: any) => {
      const { value } = item;
      if (value === e) {
        params = item;
      }
    });
    this.text = params.name;
    this.$emit('dropdownChange', params);
  }
}
