/* eslint-disable @typescript-eslint/naming-convention */

// import * as Services from '@/services/api';
// import dayjs from 'dayjs';
// import { groupAry } from '@/components';
import { generatePPT } from './ppt';
// eslint-disable-next-line max-len, @typescript-eslint/explicit-module-boundary-types
const exportPPT = async function (pptDatas: any): Promise<void> {
  const {
    times,
    flows,
    nums,
    percents,
    newNums,
    oldNums,
    newPercents,
  } = pptDatas.touristTrend;
  const {
    arrivalNum,
    numCompared,
    peopleVisit,
    visitCompared,
    newTouristNum,
    newNumCompared,
    lastRatio,
    xArrivalName,
    xArrivalNum,
    xNumCompared,
  } = pptDatas;
  const lists = [
    {
      title: '客流概况 - 客流趋势',
      labels: times, // 横轴标签
      datas: [flows, nums, percents], // 数据
      names: ['人次', '人数', '人均到访频次'], // 图例（按照顺序）
      cattlabels: ['人数', '人均到访频次'], // 纵轴名称和单位
      showType: 1,
      bartype: 1,
      texts: [
        {
          num: arrivalNum,
          name: '到访客流总人数',
          type: isNaN(numCompared) ? numCompared : `${numCompared}%`,
        },
        {
          num: xArrivalNum,
          name: xArrivalName,
          type: isNaN(xNumCompared) ? xNumCompared : `${xNumCompared}%`,
        },
        {
          num: peopleVisit,
          name: '到访客流总人次',
          type: isNaN(visitCompared) ? visitCompared : `${visitCompared}%`,
        },
      ],
    },
    {
      title: '客流概况 - 新客趋势',
      labels: times,
      datas: [newNums, oldNums, newPercents],
      names: ['新客人数', '老客人数', '新客占比'],
      cattlabels: ['人数', '占比(%)'],
      showType: 1,
      bartype: 2,
      texts: [
        {
          num: newTouristNum,
          name: '新客流总数',
          type: isNaN(newNumCompared) ? newNumCompared : `${newNumCompared}%`,
        },
        {
          num: newTouristNum / arrivalNum ? `${((newTouristNum / arrivalNum) * 100).toFixed(2)}%` : '无法比较',
          name: '新客流占比',
          type: !isNaN(newTouristNum / arrivalNum) && !isNaN(lastRatio) ? `${((newTouristNum / arrivalNum - lastRatio) * 100).toFixed(2)}%` : '无法比较',
        },
      ],
    },
    {
      title: '客流概况 - 客群趋势及城市分布',
      labels: ['本地', '外地'],
      labels1: pptDatas.cityOption.labels,
      datas: pptDatas.localTouristOption.datas,
      datas1: pptDatas.cityOption.datas,
      showType: 2,
      num1Name: '到访本地客流占比 ',
      num1: isNaN(pptDatas.localTouristOption.datas[0]) ? pptDatas.localTouristOption.datas[0] : `${pptDatas.localTouristOption.datas[0]}%`,
      num2Name: '到访外地客流占比 ',
      num2: isNaN(pptDatas.localTouristOption.datas[1]) ? pptDatas.localTouristOption.datas[1] : `${pptDatas.localTouristOption.datas[1]}%`,
      num3Name: '外地客群城市TOP10分别是: ',
      num3: pptDatas.cityOption.labels,
    },
    {
      title: '商圈覆盖 - 网格分布',
      showType: 5,
      data: pptDatas.mallInfo.data,
      url: pptDatas.mallInfo.url,
    },
    {
      title: '基础客流画像 - 年龄占比',
      showType: 3, // 条形图
      ...pptDatas.agePortraitRes,
      unit: '岁',
      prefix: '',
    },
    {
      title: '基础客流画像 - 性别占比',
      showType: 4, //  饼图
      ...pptDatas.sexPortraitRes,
      unit: '',
      prefix: '',
      name: '男女性别占比',
    },
    {
      title: '基础客流画像 - 学历占比',
      showType: 3, // 条形图
      ...pptDatas.education,
      unit: '',
      prefix: '',
    },
    {
      title: '基础客流画像 - 人生阶段占比',
      showType: 4, //  饼图
      ...pptDatas.lifeStage,
      unit: '',
      prefix: '',
      name: '人生阶段占比',
    },
    {
      title: '基础客流画像 - 婚姻状态占比',
      showType: 4, //  饼图
      ...pptDatas.maritalStatus,
      unit: '',
      prefix: '',
      name: '婚姻状态占比',
    },
    {
      title: '基础客流画像 - 消费水平占比',
      showType: 4, //  饼图
      ...pptDatas.consumptionlevel,
      unit: '',
      prefix: '',
      name: '消费水平占比',
    },
    {
      title: '财富客流画像 - 购物商场到访频次占比',
      showType: 3, // 条形图
      ...pptDatas.frequencyOfVisits,
      unit: '',
      prefix: '到访',
    },
    {
      title: '基础客流画像 - 是否有车占比',
      showType: 4, //  饼图
      ...pptDatas.vehicle,
      unit: '',
      prefix: '',
      name: '是否有车占比',
    },
    {
      title: '财富客流画像 - 居住社区房价占比',
      showType: 3, // 条形图
      ...pptDatas.housingPrice,
      unit: '',
      prefix: '社区房价',
    },
    {
      title: '财富客流画像 - 餐饮消费频次占比',
      showType: 3, // 条形图
      ...pptDatas.restaurantConsume,
      unit: '',
      prefix: '餐饮消费',
    },
    {
      title: '财富客流画像 - 餐饮消费水平占比',
      showType: 3, // 条形图
      ...pptDatas.restaurantLevel,
      unit: '',
      prefix: '餐饮消费水平',
    },
    {
      title: '财富客流画像 - 手机品牌占比',
      showType: 3, // 条形图
      ...pptDatas.phoneType,
      unit: '',
      prefix: '手机品牌是',
    },
    {
      title: '财富客流画像 - 手机价格占比',
      showType: 3, // 条形图
      ...pptDatas.phonePrice,
      unit: '',
      prefix: '手机价格',
    },
    {
      title: '财富客流画像 - 到访4S店品牌占比',
      showType: 3, // 条形图
      ...pptDatas.arrival,
      unit: '4S店',
      prefix: '到访',
    },
    {
      title: '财富客流画像 - 餐厅占比',
      showType: 3, // 条形图
      ...pptDatas.resturantOption,
      unit: '',
      prefix: '',
    },
    {
      title: '财富客流画像 - 购物占比',
      showType: 3, // 条形图
      ...pptDatas.vehicle2,
      unit: '',
      prefix: '',
    },
    {
      title: '财富客流画像 - 休闲娱乐占比',
      showType: 3, // 条形图
      ...pptDatas.frequencyOfLeisure,
      unit: '',
      prefix: '',
    },
    {
      title: '财富客流画像 - 运动健身占比',
      showType: 3, // 条形图
      ...pptDatas.frequencyOfSports,
      unit: '',
      prefix: '',
    },
    {
      title: '财富客流画像 - 汽车占比',
      showType: 3, // 条形图
      ...pptDatas.frequencyOfCar,
      unit: '',
      prefix: '',
    },
  ];


  // await getFlowData(lists, mallId, options.duration);
  const options = {
    mallname: pptDatas.mallname,
    duration: pptDatas.duration,
    dates: pptDatas.name.substring(0, 21).replace(/-/g, '')
      .split('至'),
    type: pptDatas.type.split('统计报告')[0],
  };
  generatePPT(lists, options);
};

// const getFlowData = async function (lists: any[], mallId: string, duration: string) {
//   const reportDetail = JSON.parse(localStorage.getItem('reportDetail') as any);
//   const { beforeTime, dataDate, dayList, lastDayList, reportPage } = reportDetail;
//   const dayLists = groupAry(dayList, 60);
//   // 客留趋势
//   await Promise.all(dayLists.map(async (item: string[]) => {
//     const params = {
//       mall_id: mallId,
//       date_ary: item,
//       duration: 'd',
//     };
//     const result = await Services.passengerFlowData(params);
//     return result;
//   })).then(async (results: any) => {
//     const times: Array<string> = [];
//     const flows: Array<string> = [];
//     const newNums: Array<string> = [];
//     const newPercents: Array<string> = [];
//     const nums: Array<string> = [];
//     const percents: Array<string> = [];
//     const oldNums: Array<string> = [];
//     const dataObj: any = {};
//     results.forEach((res: any) => {
//       const {
//         times: times2,
//         percents: percents2,
//         flows: flows2,
//         nums: nums2,
//         newPercents: newPercents2,
//         newNums: newNums2,
//         oldNums: oldNums2,
//         dataObj: dataObj2,
//       } = res;
//       times.push(...times2);
//       percents.push(...percents2);
//       newPercents.push(...newPercents2);
//       newNums.push(...newNums2);
//       oldNums.push(...oldNums2);
//       nums.push(...nums2);
//       flows.push(...flows2);
//       Object.assign(dataObj, dataObj2);
//     });
//     // 客流趋势
//     const datas = [
//       {
//         labels: times,
//         values: flows,
//       },
//       {
//         labels: times,
//         values: nums,
//       },
//       {
//         labels: times,
//         values: percents,
//       },
//     ];
//     // 新客占比
//     const datas1 = [
//       {
//         labels: times,
//         values: newNums,
//       },
//       {
//         labels: times,
//         values: oldNums,
//       },
//       {
//         labels: times,
//         values: newPercents,
//       },
//     ];
//     // 查询 总人数 和较上次变化
//     const p: any = {
//       mall_id: mallId,
//       duration: reportPage.dataType,
//       type: '301',
//       property: '1',
//       subtype: '1',
//       date: beforeTime,
//     };
//     const compareed = await getCompareData(p, dataDate);
//     // 查询 总人次 和较上次变化
//     p.property = '2';
//     p.duration = duration;
//     p.date = beforeTime;
//     const compareed1 = await getCompareData(p, dataDate);
//     lists.push({
//       datas,
//       names: ['人次', '人数', '人均到访频次'],
//       cattlabels: ['人数', '人均到访频次'],
//       showType: 2,
//       bartype: 1,
//       title: '客流概况 - 客流趋势',
//       num1: compareed.data,
//       num1Name: '到访新客流总数',
//       num2: compareed.compare,
//       num3: compareed1.data,
//       num3Name: '到访客流总人次',
//       num4: compareed1.compare,
//     });

//     // 本周
//     const res5 = await Services.getMallPortaitsByMallIdSSvc({
//       mall_id: mallId,
//       duration: 'd',
//       type: '1',
//       property: '50',
//       date_ary: dayList,
//     });
//     // 上周
//     const res6 = await Services.getMallPortaitsByMallIdSSvc({
//       mall_id: mallId,
//       duration: 'd',
//       type: '1',
//       property: '50',
//       date_ary: lastDayList,
//     });
//     const newTouristNum = res5?.s_new_num !== -1 ? res5.s_new_num : '暂无数据';
//     const newNumCompared = res5?.s_new_num !== -1 && res6?.s_new_num !== -1
//       ? `${(
//         ((res5?.s_new_num - res6?.s_new_num) / res6?.s_new_num)
//         * 100
//       ).toFixed(2)}`
//       : '无法比较';
//     const arrivalNum = compareed.data;
//     const newPer = ((newTouristNum / arrivalNum) * 100).toFixed(2);
//     const lastRatio = res6?.s_new_num !== -1 && compareed.data !== -1
//       ? res6.s_new_num / compareed.data0
//       : -1;
//     const newPerCompare = `${((newTouristNum / arrivalNum - lastRatio) * 100).toFixed(2)}%`;
//     lists.push({
//       datas: datas1,
//       names: ['新客人数', '老客人数', '新客占比'],
//       cattlabels: ['人数', '占比(%)'],
//       showType: 2,
//       bartype: 2,
//       title: '客流概况 - 新客趋势',
//       num1: newTouristNum,
//       num1Name: '新客流总数',
//       num2: newNumCompared,
//       num3: newPer,
//       num3Name: '新客流占比',
//       num4: lastRatio === -1 ? '无法比较' : newPerCompare,
//     });
//   });
// };

// const getCompareData = async function (p: any, dataDate: string) {
//   // 上周人数
//   const res: any = await Services.getMallRankDataBasesMallRankingSvc(p);
//   // 本周人数
//   // eslint-disable-next-line no-param-reassign
//   p.date = dataDate;
//   const res1: any = await Services.getMallRankDataBasesMallRankingSvc(p);
//   const compare = res1?.data !== -1 && res?.data !== -1
//     ? `${(((res1.data - res.data) / res.data) * 100).toFixed(2)}`
//     : '无法比较';
//   return {
//     data0: res.data || '暂无数据',
//     data: res1.data || '暂无数据',
//     compare,
//   };
// };

export default exportPPT;
