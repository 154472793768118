/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import PptxGenJS from 'pptxgenjs';
import dayjs from 'dayjs';

/**
 * 生成PPT并返回URL路径
 * @param {*} list 模板数据列表
 */
export async function generatePPT(list: any[], params: { dates: string[], mallname: string, type: string }) {
  const { dates, mallname, type } = params;
  let type1 = type;
  if (type === '节假日') {
    type1 = '年';
  }
  const pptx: any = new PptxGenJS();
  // 1英寸换算192px
  const unit = 192;
  const leftX = 0.63;
  const rightX = 9.43;
  const fontsize = 0.0134; // 1 fontsize ~= 0.0134英寸
  const fontFace = 'Microsoft YaHei';
  const tilteOption = {
    x: leftX,
    y: 0.46,
    fontSize: 18,
    fontFace,
    color: '0F1829',
    bold: true,
  };

  // Metadata Properties
  pptx.author = 'marsliang@tencent.com';
  pptx.title = '客留统计报告PPT';
  pptx.layout = 'LAYOUT_16x9';
  // pptx.fontFace = 'PingFang SC';
  // 封面模版页
  pptx.defineSlideMaster({
    title: 'COVER_SLIDE',
    background: { path: 'https://industry.map.qq.com/cloud/mall/pptx/bg.png' },
    objects: [
      {
        image: {
          x: 8.3,
          y: 0.2,
          w: 268 / unit,
          h: 40 / unit,
          path: 'https://industry.map.qq.com/cloud/mall/pptx/logo-title-white.png',
        },
      },
    ],
  });

  // 内容模版页
  pptx.defineSlideMaster({
    title: 'CONTENT_SLIDE',
    background: { color: '#ffffff' },
    objects: [
      // {
      //   line: {
      //     x: 104 / unit,
      //     y: 0,
      //     w: 0,
      //     h: 1060 / unit,
      //     line: { color: '#F2F4F7', width: 1 },
      //   },
      // },
      // {
      //   rect: {
      //     x: 94 / unit,
      //     y: 113 / unit,
      //     w: 20 / unit,
      //     h: 46 / unit,
      //     fill: { color: '51CA6D' },
      //   },
      // },
      {
        image: {
          x: 8.3,
          y: 0.2,
          w: 268 / unit,
          h: 40 / unit,
          path: 'https://industry.map.qq.com/cloud/mall/pptx/logo-title-blue.png',
        },
      },
    ],
    slideNumber: { x: 0.10833, y: 5.21667, fontSize: 12, color: '#000000', fontFace },
  });

  // 封面
  const slideFirst = pptx.addSlide({ masterName: 'COVER_SLIDE' });
  // eslint-disable-next-line no-param-reassign
  slideFirst.addText(`${mallname}\n客流大数据分析报告`, {
    x: 120 / unit,
    y: 388 / unit,
    color: '#ffffff',
    fontSize: 32,
    fontFace,
  });
  // slideFirst.addText(`${pinyin(
  //   name
  //   , { toneType: 'none' },
  // )} passenger flow statistics report`, {
  //   x: 120 / unit,
  //   y: 554 / unit,
  //   color: '#666666',
  //   fontSize: 18,
  //   fontFace: 'Microsoft YaHei',
  // });

  slideFirst.addText(`${dates[0]}~${dates[1]}（${type}）`, {
    x: 120 / unit,
    y: 580 / unit,
    color: '#ffffff',
    fontSize: 17,
    fontFace,
  });
  // slideFirst.addShape(pptx.ShapeType.rect, {
  //   fill: {
  //     color: '#50E177',
  //   },
  //   x: ((date[0].length + date[1].length) * 50) / unit,
  //   y: 650 / unit,
  //   w: 153 / unit,
  //   h: 8 / unit,
  // });
  const textsOptions = {
    x: leftX,
    y: 0.65,
    h: 1.1,
    w: 10 - leftX,
    color: '#000000',
    fontSize: 14,
    fontFace,
  };
  const COLORS_RYGU = ['#0062ff', '#40c8f5', '#ee5c3d', '#74d857', '#846bce'];
  const COLORS_RYGU1 = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
  const optsBarModal = {
    showValue: true,
    catAxisLabelFontSize: 6,
    catAxisLabelColor: '666666',
    catAxisMajorTickMark: 'none',
    catAxisLineSize: 0.1,
    catAxisLineColor: '000000',
    catAxisLabelFontFace: fontFace,
    valAxisLineColor: '000000',
    valAxisLabelFontSize: 8,
    valAxisLabelColor: '666666',
    valAxisLineSize: 0.1,
    valGridLine: { color: '666666', style: 'solid', size: 0.1 },
    lineSize: 1, // 值线的粗细
    lineDataSymbol: 'none',
    dataLabelFontSize: 8,
    dataLabelFontFace: fontFace,
    dataLabelColor: '000000',
  };
  const optsChartPieModal = {
    holeSize: 65,
    showLabel: false,
    showValue: true,
    showPercent: false,
    showLegend: true,
    legendPos: 't',
    legendFontSize: 9,
    legendFontFace: fontFace,
    legendColor: '000000',
    chartColors: COLORS_RYGU1,
    dataLabelFontSize: 8,
    dataLabelFontFace: fontFace,
    dataLabelColor: '000000',
    dataLabelFormatCode: '0.00%',
    showLeaderLines: true,
    dataLabelPosition: 'inEnd',
    titlePos: { x: 47, y: 51 },
  };
  // 内容页
  list.forEach((item: any) => {
    const slide = pptx.addSlide({ masterName: 'CONTENT_SLIDE' });
    slide.addText(`${item.title}`, tilteOption);
    const texts = { text: `${params.dates[0]}-${params.dates[1]}期间到访“${params.mallname}”商场的全部客流中：`, options: { breakLine: true } };
    // const COLORS_RYGU = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    switch (item.showType) {
      // 1: 客流趋势 和 新客趋势
      case 1: {
        addFlows(slide, item, texts);
        break;
      }
      // 2: 客流内地、外地、客群分布
      case 2: {
        addFlows1(slide, item, texts);
        break;
      }
      // 3:条形图
      case 3: {
        addBAR(slide, item, texts);
        break;
      }
      // 环形图
      case 4: {
        addDOUGHNUT(slide, item, texts);
        break;
      }
      // base64图片
      case 5: {
        addImages(slide, item, texts);
        break;
      }
      // 2:条形图
      case 22: {
        const optsBar = {
          x: 190 / unit,
          y: 450 / unit,
          w: 845 / unit,
          h: 360 / unit,
          showValue: true,
          dataLabelFontSize: 6,
          dataLabelFormatCode: '0.0',
          barGapWidthPct: 220,
          catAxisLabelFontSize: 6,
          catAxisLabelColor: '666666',
          catAxisMajorTickMark: 'none',
          valAxisLineColor: 'ffffff',
          valAxisLabelFontSize: 6,
          valAxisLabelColor: '666666',
          chartColors: ['47ed6e'],
          valGridLine: {
            size: 0.1,
            color: 'E7E7E7',
          },
        };
        slide.addChart(pptx.charts.BAR, item.datas, optsBar);
        break;
      }
    }
  });

  // 1: 客流趋势 和 新客趋势
  function addFlows(slide: any, item: any, texts: any) {
    const textsArr = [texts];
    for (const el of item.texts) {
      textsArr.push({ text: el.name });
      textsArr.push({ text: el.num, options: { color: '#699bd0' } });
      textsArr.push({ text: ` ( 较上${type1} ` });
      textsArr.push({ text: el.type, options: { color: el.type === '无法比较' || parseFloat(el.type.split('%')[0]) > 0 ? '#167419' : '#b02318' } });
      textsArr.push({ text: ')', options: { breakLine: true } });
    }
    slide.addText(
      textsArr,
      // [
      //   texts,
      //   { text: `${item.num1Name} ` },
      //   { text: item.num1, options: { color: '#699bd0' } },
      //   { text: ` ( 较上${type1} ` },
      //   { text: item.num2, options: { color: item.num2 === '无法比较' || parseFloat(item.num2.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
      //   { text: ')', options: { breakLine: true } },

      //   { text: `${item.num1Name} ` },
      //   { text: item.num1, options: { color: '#699bd0' } },
      //   { text: ` ( 较上${type1} ` },
      //   { text: item.num2, options: { color: item.num2 === '无法比较' || parseFloat(item.num2.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
      //   { text: ')', options: { breakLine: true } },

      //   { text: `${item.num3Name} ` },
      //   { text: item.num3, options: { color: '#699bd0' } },
      //   { text: ` ( 较上${type1} ` },
      //   { text: item.num4, options: { color: item.num4 === '无法比较' || parseFloat(item.num4.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
      //   { text: ')' },
      // ],
      textsOptions,
    );
    const optsBar = {
      x: leftX,
      y: 2.25,
      w: 8.8,
      h: 3,
      ...optsBarModal,
      valAxes: [
        {
          valAxisTitle: item.cattlabels[0],
          valAxisLineShow: true,
          valGridLine: { color: '666666', style: 'dot', size: 0.1 },
        },
        {
          valAxisTitle: item.cattlabels[1],
          valAxisLineShow: true,
          valAxisMinVal: 0,
          // valAxisMajorUnit: item.bartype === 2 ? 10 : 0.3,
          lineSize: 1.5, // 值线的粗细
        },
      ],
      catAxes: [
        {
          showCatAxisTitle: false,
        },
        {
          catAxisHidden: true,
        },
      ],
    };
    optsBar.showValue = false;
    const { labels } = item;
    const chartTypes = [
      {
        type: pptx.charts.BAR,
        data: [
          {
            name: item.names[0],
            labels,
            values: item.datas[0],
          },
          {
            name: item.names[1],
            labels,
            values: item.datas[1],
          },
        ],
        options: {
          barGrouping: item.bartype === 2 ? 'stacked' : 'clustered',
          barOverlapPct: -20,
          chartColors: COLORS_RYGU,
        },
      },
      {
        type: pptx.charts.LINE,
        data: [
          {
            name: item.names[2],
            labels,
            values: item.datas[2],
          },
        ],
        options: {
          chartColors: [COLORS_RYGU[2]],
          barGrouping: 'standard',
          secondaryValAxis: !!optsBar.valAxes,
          secondaryCatAxis: !!optsBar.catAxes,
        },
      },
    ];
    slide.addChart(chartTypes, optsBar);
    slide.addText(
      item.cattlabels[0],
      {
        x: leftX,
        y: 2.15,
        fontSize: 8,
        fontFace,
        color: '666666',
      },
    );
    const labelWidth1 = item.cattlabels[1].length * 8 * fontsize;
    slide.addText(
      item.cattlabels[1],
      {
        x: rightX - labelWidth1, // 1 fontsize ~= 0.0134英寸
        y: 2.15,
        h: 0.15,
        fontSize: 8,
        fontFace,
        color: '666666',
      },
    );
    // 图例
    const labelWidth2 = item.names.join('').length * 8 * fontsize + item.names.length * 0.2;
    let w1 = rightX - labelWidth2;
    addLegend(slide, pptx, fontsize, COLORS_RYGU[0], item.names[0], w1);
    w1 = w1 + 0.3 + item.names[0].length * 8 * fontsize;
    addLegend(slide, pptx, fontsize, COLORS_RYGU[1], item.names[1], w1);
    w1 = w1 + 0.3 + item.names[1].length * 8 * fontsize;
    addLegend(slide, pptx, fontsize, COLORS_RYGU[2], item.names[2], w1);
  }

  // 2: 客流内地、外地、客群分布
  function addFlows1(slide: any, item: any, texts: any) {
    textsOptions.y = 0.8;
    slide.addText(
      [
        texts,
        { text: item.num1Name },
        { text: item.num1, options: { color: '#699bd0', breakLine: true } },
        { text: item.num2Name },
        { text: item.num2, options: { color: '#699bd0', breakLine: true } },
        { text: item.num3Name },
        { text: item.num3 },
      ],
      textsOptions,
    );
    const optsChartPie = {
      x: leftX - 0.3,
      y: 2.4,
      w: 2.7,
      h: 2.7,
      ...optsChartPieModal,
    };
    const dataChartPieStat = [{
      labels: item.labels,
      values: item.datas.map((e: number) => e / 100),
    }];
    slide.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsChartPie);
    const optsBar = {
      x: leftX + 2.5,
      y: 2.25,
      w: 6.3,
      h: 3,
      dataLabelFormatCode: '0.00',
      ...optsBarModal,
      chartColors: [COLORS_RYGU[0]],
    };
    slide.addChart(pptx.charts.BAR, [{
      labels: item.labels1,
      values: item.datas1,
    }], optsBar);
    slide.addText(
      '占比(%)',
      {
        x: leftX + 2.5, // 1 fontsize ~= 0.0134英寸
        y: 2.15,
        h: 0.15,
        fontSize: 8,
        fontFace,
        color: '666666',
      },
    );
  }

  // 3:条形图
  function addBAR(slide: any, item: any, texts: any) {
    textsOptions.y = 0.8;
    slide.addText(
      [
        texts,
        { text: item.prefix + item.num1Name + item.unit },
        { text: '的客流占比最高，有 ' },
        { text: item.num1, options: { color: '#699bd0' } },
        { text: '%', options: { color: '#699bd0', breakLine: true } },
        { text: item.prefix + item.num2Name + item.unit },
        { text: '的客流占比排名第二，有 ' },
        { text: item.num2, options: { color: '#699bd0' } },
        { text: '%', options: { color: '#699bd0', breakLine: true } },
        { text: item.prefix + item.num3Name + item.unit },
        { text: '的客流占比排名第三，有 ' },
        { text: item.num3, options: { color: '#699bd0' } },
        { text: '%', options: { color: '#699bd0', breakLine: true } },
      ],
      textsOptions,
    );
    const optsBar = {
      x: leftX,
      y: 2.25,
      w: 8.8,
      h: 3,
      dataLabelFormatCode: '0.00',
      ...optsBarModal,
      chartColors: [COLORS_RYGU[0]],
    };
    slide.addChart(pptx.charts.BAR, [{
      labels: item.labels,
      values: item.datas,
    }], optsBar);
    slide.addText(
      '占比(%)',
      {
        x: leftX, // 1 fontsize ~= 0.0134英寸
        y: 2.15,
        h: 0.15,
        fontSize: 8,
        fontFace,
        color: '666666',
      },
    );
  }

  // 环形图
  function addDOUGHNUT(slide: any, item: any, texts: any) {
    if (item.num3Name) {
      textsOptions.y = 0.8;
      slide.addText(
        [
          texts,
          { text: item.prefix + item.num1Name + item.unit },
          { text: '的客流占比最高，有 ' },
          { text: item.num1, options: { color: '#699bd0' } },
          { text: '%', options: { color: '#699bd0', breakLine: true } },
          { text: item.prefix + item.num2Name + item.unit },
          { text: '的客流占比排名第二，有 ' },
          { text: item.num2, options: { color: '#699bd0' } },
          { text: '%', options: { color: '#699bd0', breakLine: true } },
          { text: item.prefix + item.num3Name + item.unit },
          { text: '的客流占比排名第三，有 ' },
          { text: item.num3, options: { color: '#699bd0' } },
          { text: '%', options: { color: '#699bd0', breakLine: true } },
        ],
        textsOptions,
      );
    } else {
      slide.addText(
        [
          texts,
          { text: item.prefix + item.num1Name + item.unit },
          { text: '客流占比 ' },
          { text: item.num1, options: { color: '#699bd0' } },
          { text: '%；', options: { color: '#699bd0' } },
          { text: item.prefix + item.num2Name + item.unit },
          { text: '客流占比' },
          { text: item.num2, options: { color: '#699bd0' } },
          { text: '%', options: { color: '#699bd0', breakLine: true } },
        ],
        textsOptions,
      );
    }
    // position.titlePos = { x: (2 * leftX + position.w) / 2 * unit, y: (2 * position.y + position.h) / 2 * unit };
    const optsChartPie = {
      showTitle: true,
      title: item.name,
      titleAlign: 'center',
      titleFontSize: 9,
      titleFontFace: fontFace,
      x: leftX,
      y: 1.65,
      w: 8.8,
      h: 3.6,
      ...optsChartPieModal,
    };
    const dataChartPieStat = [{
      labels: item.labels,
      values: item.datas.map((e: number) => e / 100),
    }];
    slide.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsChartPie);
  }

  // 商圈覆盖+截图
  function addImages(slide: any, item: any, texts: any) {
    textsOptions.y = 0.7;
    slide.addText(
      [
        texts,
        { text: '商圈总面积 ' },
        { text: `${item.data[0].num} `, options: { color: '#699bd0' } },
        { text: ` 平方公里（较上${type1}` },
        { text: item.data[0].ratio, options: { color: item.data[0].ratio === '无法比较' || parseFloat(item.data[0].ratio.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
        { text: '）；商圈总人口 ' },
        { text: `${item.data[1].num} `, options: { color: '#699bd0' } },
        { text: `（较上${type1}` },
        { text: item.data[1].ratio, options: { color: item.data[1].ratio === '无法比较' || parseFloat(item.data[1].ratio.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
        { text: '）', options: { breakLine: true } },
        { text: '商圈客流人数' },
        { text: `${item.data[2].num} `, options: { color: '#699bd0' } },
        { text: `（较上${type1}` },
        { text: item.data[2].ratio, options: { color: item.data[2].ratio === '无法比较' || parseFloat(item.data[2].ratio.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
        { text: '）；商圈渗透率 ' },
        { text: `${item.data[3].num} `, options: { color: '#699bd0' } },
        { text: `（较上${type1}` },
        { text: item.data[3].ratio, options: { color: item.data[3].ratio === '无法比较' || parseFloat(item.data[3].ratio.split('%')[0]) > 0 ? '#167419' : '#b02318' } },
        { text: '）' },
      ],
      textsOptions,
    );
    slide.addImage({
      data: item.url,
      x: 1.8,
      y: 2,
      w: 6.22,
      h: 3.2,
    });
  }

  // Last Page
  const slideLast = pptx.addSlide({ masterName: 'COVER_SLIDE' });
  slideLast.addText('THANK YOU', {
    x: 120 / unit,
    y: 458 / unit,
    color: '#ffffff',
    fontFace: 'Microsoft YaHei',
    fontSize: 32,
  });
  // slideLast.addText(`统计周期 ${date[0]}~${date[1]}`, {
  //   x: 120 / unit,
  //   y: 650 / unit,
  //   color: '#ffffff',
  //   fontFace: 'Microsoft YaHei',
  //   fontSize: 24,
  // });
  // slideLast.addShape(pptx.ShapeType.rect, {
  //   fill: {
  //     color: '#50E177',
  //   },
  //   x: ((date[0].length + date[1].length + 6) * 50) / unit,
  //   y: 650 / unit,
  //   w: 153 / unit,
  //   h: 8 / unit,
  // });

  pptx.writeFile({ fileName: `${mallname}客流统计报告_${dayjs().unix()}.pptx` });
  pptx.write({ outputType: 'blob' });
}

function addLegend(slide: any, pptx: any, fontsize: number, color: string, name: any, x: number) {
  slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
    x,
    y: 1.97,
    w: 8 * fontsize,
    h: 8 * fontsize,
    rectRadius: 8 * fontsize / 2,
    fill: { color },
    line: color,
    lineSize: 0.001,
  });
  slide.addText(
    name,
    {
      x: x + 0.03,
      y: 1.95,
      h: 0.15,
      fontSize: 8,
      fontFace: 'Microsoft YaHei',
      color: '666666',
    },
  );
}
